import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Admin = {
  __typename?: 'Admin';
  accountAccess: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  channels?: Maybe<Array<Channel>>;
  codeTwoFaAuthentication?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hasTwoFaAuthentication?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  nickName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneStatus?: Maybe<PhoneStatus>;
  qrCodeTwoFaAuthentication?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<RoleAdminDto>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['Float']>;
  userName?: Maybe<Scalars['String']>;
};

export type AdminAuthDto = {
  __typename?: 'AdminAuthDto';
  accessToken?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hasVerifyOtp?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneStatus?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<RoleAdminDto>>;
};

export type AdminDto = {
  __typename?: 'AdminDto';
  avatar?: Maybe<Scalars['String']>;
  fullname?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  positionLabel?: Maybe<Scalars['String']>;
  refId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AdminInputDto = {
  accountAccess?: InputMaybe<Scalars['Boolean']>;
  avatar?: InputMaybe<Scalars['String']>;
  channels?: InputMaybe<Array<Scalars['String']>>;
  codeTwoFaAuthentication?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  hasTwoFaAuthentication?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  nickName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  qrCodeTwoFaAuthentication?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
  userName?: InputMaybe<Scalars['String']>;
};

export type AdminInputOtpVerifyPhoneNumberDto = {
  recaptchaToken: Scalars['String'];
};

export type AdminInputOtpVerifyPhoneNumberUserDto = {
  phoneNumber: Scalars['String'];
  recaptchaToken: Scalars['String'];
};

export type AdminInputUpdatePhoneNumberUserDto = {
  code?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  phoneStatus: PhoneStatus;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type AdminInputVerifyPhoneNumberDto = {
  code?: InputMaybe<Scalars['String']>;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type AdminPaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchAdminInput>;
};

export type AdminPaginateDto = {
  __typename?: 'AdminPaginateDto';
  data: Array<Admin>;
  pagination: PaginateDto;
};

export type AdminStaffPaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchAdminStaffInput>;
};

export type AdminStaffPaginateDto = {
  __typename?: 'AdminStaffPaginateDto';
  data: Array<AdminDto>;
  pagination: PaginateDto;
};

export type AdminVerifyPhoneNumberDto = {
  __typename?: 'AdminVerifyPhoneNumberDto';
  accessToken?: Maybe<Scalars['String']>;
};

export type AuthInputDto = {
  authenticator?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
  refId: Scalars['String'];
};

export type BrandPaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchBrandInput>;
};

export type BrandPaginateDto = {
  __typename?: 'BrandPaginateDto';
  data: Array<Brand>;
  pagination: PaginateDto;
};

export type CartRule = {
  __typename?: 'CartRule';
  action: Scalars['String'];
  channelId: Scalars['String'];
  expires: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  refId: Scalars['String'];
};

export type CartRulePaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchCartRuleInput>;
};

export type CartRulePaginateDto = {
  __typename?: 'CartRulePaginateDto';
  data: Array<CartRule>;
  pagination: PaginateDto;
};

export type CategoriesPaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchCategoriesInput>;
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  refId: Scalars['String'];
};

export type CategoryPaginateDto = {
  __typename?: 'CategoryPaginateDto';
  data: Array<Category>;
  pagination: PaginateDto;
};

export type Channel = {
  __typename?: 'Channel';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  orderSource: Array<Scalars['String']>;
  refId: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ChannelPaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchChannelInput>;
  showAll?: InputMaybe<Scalars['Boolean']>;
};

export type ChannelPaginateDto = {
  __typename?: 'ChannelPaginateDto';
  data: Array<Channel>;
  pagination: PaginateDto;
};

export type CheckWatchListProductResponse = {
  __typename?: 'CheckWatchListProductResponse';
  data?: Maybe<Array<WatchListProduct>>;
};

export type CreateWatchListProductRequest = {
  name?: InputMaybe<Scalars['String']>;
};

export type DeletedResultDto = {
  __typename?: 'DeletedResultDto';
  deleted: Scalars['Boolean'];
  status: Scalars['Int'];
};

export type DetailWatchListProductResponse = {
  __typename?: 'DetailWatchListProductResponse';
  data?: Maybe<WatchListProduct>;
};

export enum FocType {
  ExcludeFoc = 'EXCLUDE_FOC',
  IncludeFoc = 'INCLUDE_FOC'
}

export type FilterAllStaffPerformance = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  includeStaffsWithoutSales?: InputMaybe<Scalars['Boolean']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  sort?: InputMaybe<SortDto>;
  staffs?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  store?: InputMaybe<Array<Scalars['String']>>;
};

export type FilterCartRulePerformance = {
  cartRuleIds?: InputMaybe<Array<Scalars['String']>>;
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  orderStatuses?: InputMaybe<Array<Scalars['String']>>;
  orderType?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type FilterChannelPerformance = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  orderType?: InputMaybe<Array<Scalars['String']>>;
  paymentStatus?: InputMaybe<StatusFilter>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type FilterProductPerformance = {
  brand?: InputMaybe<Array<Scalars['String']>>;
  category?: InputMaybe<Array<Scalars['String']>>;
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  filterProductType?: InputMaybe<ProductPerformanceFilterProductType>;
  focType?: InputMaybe<FocType>;
  launchAt?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Float']>;
  products?: InputMaybe<Array<Scalars['String']>>;
  sort?: InputMaybe<SortDto>;
  start?: InputMaybe<Scalars['DateTime']>;
  store?: InputMaybe<Array<Scalars['String']>>;
  watchListProduct?: InputMaybe<Scalars['String']>;
};

export type FilterProductPerformancePaging = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  orderType?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Float']>;
  paymentStatus?: InputMaybe<StatusFilter>;
  sort?: InputMaybe<SortDto>;
  start?: InputMaybe<Scalars['DateTime']>;
  store?: InputMaybe<Array<Scalars['String']>>;
};

export type FilterProvincePerformance = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  provinces?: InputMaybe<Array<Scalars['String']>>;
  sort?: InputMaybe<SortDto>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type FilterStaffPerformance = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Float']>;
  sort?: InputMaybe<SortDto>;
  staffs?: InputMaybe<Array<Scalars['String']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  store?: InputMaybe<Array<Scalars['String']>>;
};

export type FilterStorePerformance = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  orderType?: InputMaybe<Array<Scalars['String']>>;
  paymentStatus?: InputMaybe<StatusFilter>;
  start?: InputMaybe<Scalars['DateTime']>;
  store?: InputMaybe<Array<Scalars['String']>>;
};

export type FilterTotalSales = {
  channels?: InputMaybe<Array<Scalars['String']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  orderSource?: InputMaybe<Array<Scalars['String']>>;
  orderType?: InputMaybe<Array<Scalars['String']>>;
  paymentStatus?: InputMaybe<StatusFilter>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type ListWatchListProductRequest = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<Scalars['String']>;
};

export type ListWatchListProductResponse = {
  __typename?: 'ListWatchListProductResponse';
  data?: Maybe<Array<WatchListProduct>>;
};

export type LocationPaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchLocationInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addProductWatchListProduct: DetailWatchListProductResponse;
  createRole: RoleAdminDto;
  createUserByAdmin: Admin;
  createWatchListProduct: DetailWatchListProductResponse;
  deleteUserByAdmin: Admin;
  deleteUsersByAdmin: Admin;
  deleteWatchListProduct: DetailWatchListProductResponse;
  duplicateUserByAdmin: Admin;
  loginByAdmin: AdminAuthDto;
  loginByWorkInfinity: AdminAuthDto;
  removeProductWatchListProduct: DetailWatchListProductResponse;
  removeRole: DeletedResultDto;
  removeUserChannel: Admin;
  removeUserRole: Admin;
  updatePhoneNumberUsersByAdmin: Admin;
  updateRole: RoleAdminDto;
  updateUserByAdmin: Admin;
  updateWatchListProduct: DetailWatchListProductResponse;
  verifyPhoneNumberByAdmin?: Maybe<AdminVerifyPhoneNumberDto>;
};


export type MutationAddProductWatchListProductArgs = {
  id: Scalars['String'];
  product: Scalars['String'];
};


export type MutationCreateRoleArgs = {
  input: RoleAdminInputDto;
};


export type MutationCreateUserByAdminArgs = {
  input: AdminInputDto;
};


export type MutationCreateWatchListProductArgs = {
  payload: CreateWatchListProductRequest;
};


export type MutationDeleteUserByAdminArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUsersByAdminArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationDeleteWatchListProductArgs = {
  id: Scalars['String'];
};


export type MutationDuplicateUserByAdminArgs = {
  id: Scalars['String'];
};


export type MutationLoginByAdminArgs = {
  args: AuthInputDto;
};


export type MutationLoginByWorkInfinityArgs = {
  code: Scalars['String'];
};


export type MutationRemoveProductWatchListProductArgs = {
  id: Scalars['String'];
  product: Scalars['String'];
};


export type MutationRemoveRoleArgs = {
  ids: Array<Scalars['String']>;
};


export type MutationRemoveUserChannelArgs = {
  input: AdminInputDto;
};


export type MutationRemoveUserRoleArgs = {
  input: AdminInputDto;
};


export type MutationUpdatePhoneNumberUsersByAdminArgs = {
  id: Scalars['String'];
  input: AdminInputUpdatePhoneNumberUserDto;
};


export type MutationUpdateRoleArgs = {
  id: Scalars['String'];
  input: RoleAdminInputDto;
};


export type MutationUpdateUserByAdminArgs = {
  input: AdminInputDto;
};


export type MutationUpdateWatchListProductArgs = {
  id: Scalars['String'];
  payload: UpdateWatchListProductRequest;
};


export type MutationVerifyPhoneNumberByAdminArgs = {
  input: AdminInputVerifyPhoneNumberDto;
};

export type Otp = {
  __typename?: 'OTP';
  error?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type OrderBasic = {
  __typename?: 'OrderBasic';
  created?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
};

export type PaginateDto = {
  __typename?: 'PaginateDto';
  currentPage?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export enum PhoneStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type Product = {
  __typename?: 'Product';
  name: Scalars['String'];
  refId: Scalars['String'];
  sku: Scalars['String'];
};

export type ProductPaginate = {
  launchAt?: InputMaybe<Scalars['DateTime']>;
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  query?: InputMaybe<Scalars['String']>;
};

export type ProductPaginateDto = {
  __typename?: 'ProductPaginateDto';
  data: Array<Product>;
  pagination: PaginateDto;
};

export enum ProductPerformanceFilterProductType {
  ExcludingGroupProduct = 'EXCLUDING_GROUP_PRODUCT',
  IncludingGroupProduct = 'INCLUDING_GROUP_PRODUCT'
}

export type Provinces = {
  __typename?: 'Provinces';
  id: Scalars['ID'];
  name: Scalars['String'];
  refId: Scalars['String'];
};

export type ProvincesPaginateDto = {
  __typename?: 'ProvincesPaginateDto';
  data: Array<Provinces>;
  pagination: PaginateDto;
};

export type Query = {
  __typename?: 'Query';
  checkProductWatchListProduct: CheckWatchListProductResponse;
  detailReportProductPerformance: ReportProductPerformancePaginateDto;
  detailReportProductPerformanceOrder: ReportProductPerformanceOrderPaginateDto;
  detailReportStaffPerformance: ReportStaffPerformanceDetailPaginateDto;
  detailReportStaffPerformanceByBrand: ReportStaffPerformanceDetailByBrandPaginateDto;
  exportProvincePerformance: Array<ReportProvincePerformance>;
  exportReportProductPerformance: Array<ReportProductPerformance>;
  exportStaffPerformance: Array<ReportStaffPerformance>;
  findAllRole: RoleAdminPaginateDto;
  findBrandByAdmin: BrandPaginateDto;
  findBrandByRefId: Brand;
  findCartRuleByAdmin: CartRulePaginateDto;
  findCartRuleByRefId: CartRule;
  findCategoriesByAdmin: CategoryPaginateDto;
  findCategoryByRefId: Category;
  findChannelByRefId: Channel;
  findChannelsByAdmin: ChannelPaginateDto;
  findOneRole: RoleAdminDto;
  findProduct: ProductPaginateDto;
  findProvincesByAdmin: ProvincesPaginateDto;
  findStaffByAdmin: AdminStaffPaginateDto;
  findUnassignedChannel: ChannelPaginateDto;
  findUnassignedRole: RoleAdminPaginateDto;
  findUserByAdmin: Admin;
  findUsersByAdmin: AdminPaginateDto;
  findWarehouseByRefId: Warehouse;
  findWarehousesByAdmin: WarehousePaginateDto;
  listWatchListProduct: ListWatchListProductResponse;
  profileForAdmin: Admin;
  randomTwoFaAuthentication: SettingAuthenticationDto;
  reportCartRulePerformance: Array<ReportCartRulePerformance>;
  reportChannelPerformance: Array<ReportChannelPerformance>;
  reportProductPerformance: ReportProductPerformancePaginateDto;
  reportStorePerformance: Array<ReportStorePerformance>;
  reportStorePerformanceByStore: ReportStorePerformanceGroupedPaginateDto;
  reportTotalSales: Array<ReportTotalSales>;
  sendOTPVerifyPhoneNumberByAdmin?: Maybe<Otp>;
  sendOTPVerifyPhoneNumberUsersByAdmin?: Maybe<Otp>;
  summaryReportProductPerformance?: Maybe<SummaryReportProductPerformanceDto>;
};


export type QueryCheckProductWatchListProductArgs = {
  product: Array<Scalars['String']>;
};


export type QueryDetailReportProductPerformanceArgs = {
  payload: FilterProductPerformance;
};


export type QueryDetailReportProductPerformanceOrderArgs = {
  payload: FilterProductPerformance;
};


export type QueryDetailReportStaffPerformanceArgs = {
  payload: FilterStaffPerformance;
};


export type QueryDetailReportStaffPerformanceByBrandArgs = {
  payload: FilterStaffPerformance;
};


export type QueryExportProvincePerformanceArgs = {
  payload: FilterProvincePerformance;
};


export type QueryExportReportProductPerformanceArgs = {
  payload: FilterProductPerformance;
};


export type QueryExportStaffPerformanceArgs = {
  payload: FilterAllStaffPerformance;
};


export type QueryFindAllRoleArgs = {
  pagination: SearchRolePaginateInput;
};


export type QueryFindBrandByAdminArgs = {
  paginate: BrandPaginate;
};


export type QueryFindBrandByRefIdArgs = {
  refId: Scalars['String'];
};


export type QueryFindCartRuleByAdminArgs = {
  paginate: CartRulePaginate;
};


export type QueryFindCartRuleByRefIdArgs = {
  refId: Scalars['String'];
};


export type QueryFindCategoriesByAdminArgs = {
  paginate: CategoriesPaginate;
};


export type QueryFindCategoryByRefIdArgs = {
  refId: Scalars['String'];
};


export type QueryFindChannelByRefIdArgs = {
  refId: Scalars['String'];
};


export type QueryFindChannelsByAdminArgs = {
  paginate: ChannelPaginate;
};


export type QueryFindOneRoleArgs = {
  id: Scalars['String'];
};


export type QueryFindProductArgs = {
  paginate: ProductPaginate;
};


export type QueryFindProvincesByAdminArgs = {
  paginate: LocationPaginate;
};


export type QueryFindStaffByAdminArgs = {
  paginate: AdminStaffPaginate;
};


export type QueryFindUnassignedChannelArgs = {
  paginate: ChannelPaginate;
  userId: Scalars['String'];
};


export type QueryFindUnassignedRoleArgs = {
  paginate: SearchRolePaginateInput;
  userId: Scalars['String'];
};


export type QueryFindUserByAdminArgs = {
  id: Scalars['String'];
};


export type QueryFindUsersByAdminArgs = {
  pagination: AdminPaginate;
};


export type QueryFindWarehouseByRefIdArgs = {
  refId: Scalars['String'];
};


export type QueryFindWarehousesByAdminArgs = {
  paginate: WarehousePaginate;
};


export type QueryListWatchListProductArgs = {
  payload: ListWatchListProductRequest;
};


export type QueryRandomTwoFaAuthenticationArgs = {
  nickName: Scalars['String'];
};


export type QueryReportCartRulePerformanceArgs = {
  payload: FilterCartRulePerformance;
};


export type QueryReportChannelPerformanceArgs = {
  payload: FilterChannelPerformance;
};


export type QueryReportProductPerformanceArgs = {
  payload: FilterProductPerformance;
};


export type QueryReportStorePerformanceArgs = {
  payload: FilterStorePerformance;
};


export type QueryReportStorePerformanceByStoreArgs = {
  payload: FilterProductPerformancePaging;
};


export type QueryReportTotalSalesArgs = {
  payload: FilterTotalSales;
};


export type QuerySendOtpVerifyPhoneNumberByAdminArgs = {
  input: AdminInputOtpVerifyPhoneNumberDto;
};


export type QuerySendOtpVerifyPhoneNumberUsersByAdminArgs = {
  input: AdminInputOtpVerifyPhoneNumberUserDto;
};


export type QuerySummaryReportProductPerformanceArgs = {
  payload: FilterProductPerformance;
};

export type ReportCartRulePerformance = {
  __typename?: 'ReportCartRulePerformance';
  cartRule?: Maybe<CartRule>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  orderId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type ReportChannelPerformance = {
  __typename?: 'ReportChannelPerformance';
  channelId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Float']>;
};

export type ReportProductPerformance = {
  __typename?: 'ReportProductPerformance';
  adminExpense?: Maybe<Scalars['Float']>;
  brandRefId?: Maybe<Scalars['String']>;
  categoriesRefId?: Maybe<Scalars['String']>;
  channel?: Maybe<Channel>;
  channelId?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  netProfit?: Maybe<Scalars['Float']>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['String']>;
  productionCost?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  revPerUnit?: Maybe<Scalars['Float']>;
  saleExpense?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePriceNet?: Maybe<Scalars['Float']>;
  store?: Maybe<Warehouse>;
  storeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
  totalOrder?: Maybe<Scalars['Float']>;
};

export type ReportProductPerformanceOrder = {
  __typename?: 'ReportProductPerformanceOrder';
  created?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  order?: Maybe<OrderBasic>;
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePriceNet?: Maybe<Scalars['Float']>;
};

export type ReportProductPerformanceOrderPaginateDto = {
  __typename?: 'ReportProductPerformanceOrderPaginateDto';
  data: Array<ReportProductPerformanceOrder>;
  pagination: PaginateDto;
};

export type ReportProductPerformancePaginateDto = {
  __typename?: 'ReportProductPerformancePaginateDto';
  data: Array<ReportProductPerformance>;
  pagination: PaginateDto;
};

export type ReportProvincePerformance = {
  __typename?: 'ReportProvincePerformance';
  count?: Maybe<Scalars['Float']>;
  countCompleted?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  province?: Maybe<Provinces>;
  quantity?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePriceNet?: Maybe<Scalars['Float']>;
};

export type ReportStaffPerformance = {
  __typename?: 'ReportStaffPerformance';
  admin?: Maybe<AdminDto>;
  adminId?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  kpi?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type ReportStaffPerformanceDetail = {
  __typename?: 'ReportStaffPerformanceDetail';
  admin?: Maybe<AdminDto>;
  channel?: Maybe<Channel>;
  count?: Maybe<Scalars['Float']>;
  store?: Maybe<Warehouse>;
  subTotal?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type ReportStaffPerformanceDetailByBrand = {
  __typename?: 'ReportStaffPerformanceDetailByBrand';
  adminExpense?: Maybe<Scalars['Float']>;
  brand?: Maybe<Brand>;
  productionCost?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  saleExpense?: Maybe<Scalars['Float']>;
  salePrice?: Maybe<Scalars['Float']>;
  salePriceNet?: Maybe<Scalars['Float']>;
  totalOrder?: Maybe<Scalars['Float']>;
};

export type ReportStaffPerformanceDetailByBrandPaginateDto = {
  __typename?: 'ReportStaffPerformanceDetailByBrandPaginateDto';
  data: Array<ReportStaffPerformanceDetailByBrand>;
  pagination: PaginateDto;
};

export type ReportStaffPerformanceDetailPaginateDto = {
  __typename?: 'ReportStaffPerformanceDetailPaginateDto';
  data: Array<ReportStaffPerformanceDetail>;
  pagination: PaginateDto;
};

export type ReportStorePerformance = {
  __typename?: 'ReportStorePerformance';
  count?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  store?: Maybe<Warehouse>;
  storeId?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

export type ReportStorePerformanceGrouped = {
  __typename?: 'ReportStorePerformanceGrouped';
  count?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  report?: Maybe<Array<ReportStorePerformance>>;
  store?: Maybe<Warehouse>;
};

export type ReportStorePerformanceGroupedPaginateDto = {
  __typename?: 'ReportStorePerformanceGroupedPaginateDto';
  data: Array<ReportStorePerformanceGrouped>;
  pagination: PaginateDto;
};

export type ReportTotalSales = {
  __typename?: 'ReportTotalSales';
  created?: Maybe<Scalars['DateTime']>;
  total?: Maybe<Scalars['Float']>;
};

export type RoleAdminDto = {
  __typename?: 'RoleAdminDto';
  accessApi?: Maybe<Array<Scalars['String']>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Admin>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Scalars['String']>>;
  roleId?: Maybe<Scalars['Float']>;
  roleType?: Maybe<RoleType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RoleAdminInputDto = {
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<Scalars['String']>>;
  roleType?: InputMaybe<RoleType>;
};

export type RoleAdminPaginateDto = {
  __typename?: 'RoleAdminPaginateDto';
  data?: Maybe<Array<RoleAdminDto>>;
  pagination?: Maybe<PaginateDto>;
};

export enum RoleType {
  All = 'ALL',
  Custom = 'CUSTOM'
}

export type SearchAdminInput = {
  accountAccess?: InputMaybe<Scalars['Boolean']>;
  channels?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchAdminStaffInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type SearchBrandInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type SearchCartRuleInput = {
  channelIds?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Array<Scalars['String']>>;
};

export type SearchCategoriesInput = {
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SearchChannelInput = {
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SearchLocationInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type SearchRoleInput = {
  query?: InputMaybe<Scalars['String']>;
};

export type SearchRolePaginateInput = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchRoleInput>;
};

export type SearchWarehouseInput = {
  channelIds?: InputMaybe<Array<Scalars['String']>>;
  query?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SettingAuthenticationDto = {
  __typename?: 'SettingAuthenticationDto';
  codeTwoFaAuthentication: Scalars['String'];
  qrCodeTwoFaAuthentication: Scalars['String'];
};

export type SortDto = {
  sortBy?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['Float']>;
};

export enum StatusFilter {
  Confirmed = 'CONFIRMED',
  IncludingPending = 'INCLUDING_PENDING'
}

export type SummaryReportProductPerformanceDto = {
  __typename?: 'SummaryReportProductPerformanceDto';
  adminExpense: Scalars['Float'];
  includeGroupProduct?: Maybe<ProductPerformanceFilterProductType>;
  netProfit: Scalars['Float'];
  productionCost: Scalars['Float'];
  quantity: Scalars['Float'];
  revPerUnit: Scalars['Float'];
  saleExpense: Scalars['Float'];
  salePrice: Scalars['Float'];
  salePriceNet: Scalars['Float'];
};

export type UpdateWatchListProductRequest = {
  name?: InputMaybe<Scalars['String']>;
};

export type Warehouse = {
  __typename?: 'Warehouse';
  id: Scalars['ID'];
  name: Scalars['String'];
  refId: Scalars['String'];
};

export type WarehousePaginate = {
  limit?: InputMaybe<Scalars['Float']>;
  page?: InputMaybe<Scalars['Float']>;
  search?: InputMaybe<SearchWarehouseInput>;
};

export type WarehousePaginateDto = {
  __typename?: 'WarehousePaginateDto';
  data: Array<Warehouse>;
  pagination: PaginateDto;
};

export type WatchListProduct = {
  __typename?: 'WatchListProduct';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Float']>;
};

export type F_Auth_Admin_ResponseFragment = { __typename?: 'AdminAuthDto', accessToken?: string | null, id?: string | null, fullName?: string | null, avatar?: string | null, email?: string | null, error?: string | null, companyId?: string | null, phoneStatus?: string | null, phoneNumber?: string | null, hasVerifyOtp?: boolean | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, permissions?: Array<string> | null }> | null };

export type F_PaginationFragment = { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null };

export type F_ChannelFragment = { __typename?: 'Channel', id: string, refId: string, name: string, createdAt?: any | null, updatedAt?: any | null, orderSource: Array<string> };

export type F_WarehouseFragment = { __typename?: 'Warehouse', id: string, refId: string, name: string };

export type F_RoleFragment = { __typename?: 'RoleAdminDto', id?: string | null, roleId?: number | null, name?: string | null, roleType?: RoleType | null, createdAt?: any | null, updatedAt?: any | null, permissions?: Array<string> | null };

export type F_Report_PerformanceFragment = { __typename?: 'ReportChannelPerformance', created?: any | null, total?: number | null, channelId?: string | null };

export type F_Report_Total_SaleFragment = { __typename?: 'ReportTotalSales', created?: any | null, total?: number | null };

export type F_Report_StoreFragment = { __typename?: 'ReportStorePerformance', created?: any | null, total?: number | null, store?: { __typename?: 'Warehouse', name: string, refId: string } | null };

export type F_Report_Store_GroupedFragment = { __typename?: 'ReportStorePerformanceGrouped', count?: number | null, store?: { __typename?: 'Warehouse', name: string, refId: string } | null, report?: Array<{ __typename?: 'ReportStorePerformance', total?: number | null, created?: any | null }> | null };

export type F_Report_StaffFragment = { __typename?: 'ReportStaffPerformance', total?: number | null, subTotal?: number | null, count?: number | null, adminId?: number | null, kpi?: number | null, admin?: { __typename?: 'AdminDto', fullname?: string | null, avatar?: string | null, username?: string | null } | null };

export type F_Report_ProvinceFragment = { __typename?: 'ReportProvincePerformance', count?: number | null, countCompleted?: number | null, salePrice?: number | null, salePriceNet?: number | null, quantity?: number | null, province?: { __typename?: 'Provinces', name: string } | null };

export type F_CategoryFragment = { __typename?: 'Category', id: string, refId: string, name: string };

export type F_ProductFragment = { __typename?: 'Product', refId: string, name: string, sku: string };

export type F_BrandFragment = { __typename?: 'Brand', id: string, refId: string, name: string };

export type F_ProvinceFragment = { __typename?: 'Provinces', id: string, refId: string, name: string };

export type F_StaffFragment = { __typename?: 'AdminDto', id?: string | null, refId?: string | null, name?: string | null, username?: string | null, fullname?: string | null, avatar?: string | null };

export type F_Report_ProductFragment = { __typename?: 'ReportProductPerformance', created?: any | null, total?: number | null, channelId?: string | null, storeId?: string | null, brandRefId?: string | null, quantity?: number | null, salePrice?: number | null, salePriceNet?: number | null, categoriesRefId?: string | null, productionCost?: number | null, saleExpense?: number | null, adminExpense?: number | null, netProfit?: number | null, revPerUnit?: number | null, product?: { __typename?: 'Product', refId: string, name: string, sku: string } | null };

export type F_Report_Product_DetailFragment = { __typename?: 'ReportProductPerformance', created?: any | null, total?: number | null, quantity?: number | null, salePrice?: number | null, salePriceNet?: number | null, productionCost?: number | null, saleExpense?: number | null, adminExpense?: number | null, totalOrder?: number | null, netProfit?: number | null, revPerUnit?: number | null, channel?: { __typename?: 'Channel', name: string } | null, product?: { __typename?: 'Product', refId: string, name: string, sku: string } | null, store?: { __typename?: 'Warehouse', name: string, id: string, refId: string } | null };

export type F_Report_Product_OrderFragment = { __typename?: 'ReportProductPerformanceOrder', salePrice?: number | null, discount?: number | null, salePriceNet?: number | null, quantity?: number | null, created?: any | null, order?: { __typename?: 'OrderBasic', orderId?: string | null, created?: any | null } | null };

export type F_Report_Cartrule_PerformanceFragment = { __typename?: 'ReportCartRulePerformance', created?: any | null, total?: number | null, channelId?: string | null, channel?: { __typename?: 'Channel', name: string } | null, cartRule?: { __typename?: 'CartRule', name: string, refId: string, action: string, expires: any } | null };

export type F_Cart_RuleFragment = { __typename?: 'CartRule', id: string, refId: string, name: string, action: string, channelId: string };

export type F_Report_Staff_DetailFragment = { __typename?: 'ReportStaffPerformanceDetail', total?: number | null, subTotal?: number | null, count?: number | null, channel?: { __typename?: 'Channel', name: string } | null, store?: { __typename?: 'Warehouse', name: string } | null };

export type F_UserFragment = { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null };

export type AdminLoginMutationVariables = Exact<{
  args: AuthInputDto;
}>;


export type AdminLoginMutation = { __typename?: 'Mutation', loginByAdmin: { __typename?: 'AdminAuthDto', accessToken?: string | null, id?: string | null, fullName?: string | null, avatar?: string | null, email?: string | null, error?: string | null, companyId?: string | null, phoneStatus?: string | null, phoneNumber?: string | null, hasVerifyOtp?: boolean | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, permissions?: Array<string> | null }> | null } };

export type GetMyInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyInfoQuery = { __typename?: 'Query', profileForAdmin: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type RandomTwoFaAuthenticationQueryVariables = Exact<{
  nickName: Scalars['String'];
}>;


export type RandomTwoFaAuthenticationQuery = { __typename?: 'Query', randomTwoFaAuthentication: { __typename?: 'SettingAuthenticationDto', codeTwoFaAuthentication: string, qrCodeTwoFaAuthentication: string } };

export type LoginByWorkInfinityMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type LoginByWorkInfinityMutation = { __typename?: 'Mutation', loginByWorkInfinity: { __typename?: 'AdminAuthDto', accessToken?: string | null, id?: string | null, fullName?: string | null, avatar?: string | null, email?: string | null, error?: string | null, companyId?: string | null, phoneStatus?: string | null, phoneNumber?: string | null, hasVerifyOtp?: boolean | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, permissions?: Array<string> | null }> | null } };

export type SendOtpVerifyPhoneNumberByAdminQueryVariables = Exact<{
  input: AdminInputOtpVerifyPhoneNumberDto;
}>;


export type SendOtpVerifyPhoneNumberByAdminQuery = { __typename?: 'Query', sendOTPVerifyPhoneNumberByAdmin?: { __typename?: 'OTP', token?: string | null } | null };

export type VerifyPhoneNumberByAdminMutationVariables = Exact<{
  input: AdminInputVerifyPhoneNumberDto;
}>;


export type VerifyPhoneNumberByAdminMutation = { __typename?: 'Mutation', verifyPhoneNumberByAdmin?: { __typename?: 'AdminVerifyPhoneNumberDto', accessToken?: string | null } | null };

export type SendOtpVerifyPhoneNumberUsersByAdminQueryVariables = Exact<{
  input: AdminInputOtpVerifyPhoneNumberUserDto;
}>;


export type SendOtpVerifyPhoneNumberUsersByAdminQuery = { __typename?: 'Query', sendOTPVerifyPhoneNumberUsersByAdmin?: { __typename?: 'OTP', token?: string | null } | null };

export type UpdatePhoneNumberUsersByAdminMutationVariables = Exact<{
  id: Scalars['String'];
  input: AdminInputUpdatePhoneNumberUserDto;
}>;


export type UpdatePhoneNumberUsersByAdminMutation = { __typename?: 'Mutation', updatePhoneNumberUsersByAdmin: { __typename?: 'Admin', id: string, phoneNumber?: string | null, phoneStatus?: PhoneStatus | null } };

export type BrandListQueryVariables = Exact<{
  args: BrandPaginate;
}>;


export type BrandListQuery = { __typename?: 'Query', findBrandByAdmin: { __typename?: 'BrandPaginateDto', data: Array<{ __typename?: 'Brand', id: string, refId: string, name: string }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type CartRuleListQueryVariables = Exact<{
  args: CartRulePaginate;
}>;


export type CartRuleListQuery = { __typename?: 'Query', findCartRuleByAdmin: { __typename?: 'CartRulePaginateDto', data: Array<{ __typename?: 'CartRule', id: string, refId: string, name: string, action: string, channelId: string }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type CategoryListQueryVariables = Exact<{
  args: CategoriesPaginate;
}>;


export type CategoryListQuery = { __typename?: 'Query', findCategoriesByAdmin: { __typename?: 'CategoryPaginateDto', data: Array<{ __typename?: 'Category', id: string, refId: string, name: string }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type ChannelListQueryVariables = Exact<{
  args: ChannelPaginate;
}>;


export type ChannelListQuery = { __typename?: 'Query', findChannelsByAdmin: { __typename?: 'ChannelPaginateDto', data: Array<{ __typename?: 'Channel', id: string, refId: string, name: string, createdAt?: any | null, updatedAt?: any | null, orderSource: Array<string> }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type UnassignedChannelListQueryVariables = Exact<{
  userId: Scalars['String'];
  args: ChannelPaginate;
}>;


export type UnassignedChannelListQuery = { __typename?: 'Query', findUnassignedChannel: { __typename?: 'ChannelPaginateDto', data: Array<{ __typename?: 'Channel', id: string, refId: string, name: string, createdAt?: any | null, updatedAt?: any | null, orderSource: Array<string> }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type FindProductQueryVariables = Exact<{
  args: ProductPaginate;
}>;


export type FindProductQuery = { __typename?: 'Query', findProduct: { __typename?: 'ProductPaginateDto', data: Array<{ __typename?: 'Product', refId: string, name: string, sku: string }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type ProvinceListQueryVariables = Exact<{
  args: LocationPaginate;
}>;


export type ProvinceListQuery = { __typename?: 'Query', findProvincesByAdmin: { __typename?: 'ProvincesPaginateDto', data: Array<{ __typename?: 'Provinces', id: string, refId: string, name: string }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type ReportChannelPerformanceQueryVariables = Exact<{
  args: FilterChannelPerformance;
}>;


export type ReportChannelPerformanceQuery = { __typename?: 'Query', reportChannelPerformance: Array<{ __typename?: 'ReportChannelPerformance', created?: any | null, total?: number | null, channelId?: string | null }> };

export type ReportTotalSalesQueryVariables = Exact<{
  args: FilterTotalSales;
}>;


export type ReportTotalSalesQuery = { __typename?: 'Query', reportTotalSales: Array<{ __typename?: 'ReportTotalSales', created?: any | null, total?: number | null }> };

export type ReportStorePerformanceQueryVariables = Exact<{
  args: FilterStorePerformance;
}>;


export type ReportStorePerformanceQuery = { __typename?: 'Query', reportStorePerformance: Array<{ __typename?: 'ReportStorePerformance', created?: any | null, total?: number | null, store?: { __typename?: 'Warehouse', name: string, refId: string } | null }> };

export type ReportStorePerformanceByStoreQueryVariables = Exact<{
  args: FilterProductPerformancePaging;
}>;


export type ReportStorePerformanceByStoreQuery = { __typename?: 'Query', reportStorePerformanceByStore: { __typename?: 'ReportStorePerformanceGroupedPaginateDto', data: Array<{ __typename?: 'ReportStorePerformanceGrouped', count?: number | null, store?: { __typename?: 'Warehouse', name: string, refId: string } | null, report?: Array<{ __typename?: 'ReportStorePerformance', total?: number | null, created?: any | null }> | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type ReportProductPerformanceQueryVariables = Exact<{
  args: FilterProductPerformance;
}>;


export type ReportProductPerformanceQuery = { __typename?: 'Query', reportProductPerformance: { __typename?: 'ReportProductPerformancePaginateDto', data: Array<{ __typename?: 'ReportProductPerformance', created?: any | null, total?: number | null, channelId?: string | null, storeId?: string | null, brandRefId?: string | null, quantity?: number | null, salePrice?: number | null, salePriceNet?: number | null, categoriesRefId?: string | null, productionCost?: number | null, saleExpense?: number | null, adminExpense?: number | null, netProfit?: number | null, revPerUnit?: number | null, product?: { __typename?: 'Product', refId: string, name: string, sku: string } | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type ExportReportProductPerformanceQueryVariables = Exact<{
  args: FilterProductPerformance;
}>;


export type ExportReportProductPerformanceQuery = { __typename?: 'Query', exportReportProductPerformance: Array<{ __typename?: 'ReportProductPerformance', created?: any | null, total?: number | null, channelId?: string | null, storeId?: string | null, brandRefId?: string | null, quantity?: number | null, salePrice?: number | null, salePriceNet?: number | null, categoriesRefId?: string | null, productionCost?: number | null, saleExpense?: number | null, adminExpense?: number | null, netProfit?: number | null, revPerUnit?: number | null, product?: { __typename?: 'Product', refId: string, name: string, sku: string } | null }> };

export type DetailReportProductPerformanceQueryVariables = Exact<{
  args: FilterProductPerformance;
}>;


export type DetailReportProductPerformanceQuery = { __typename?: 'Query', detailReportProductPerformance: { __typename?: 'ReportProductPerformancePaginateDto', data: Array<{ __typename?: 'ReportProductPerformance', created?: any | null, total?: number | null, quantity?: number | null, salePrice?: number | null, salePriceNet?: number | null, productionCost?: number | null, saleExpense?: number | null, adminExpense?: number | null, totalOrder?: number | null, netProfit?: number | null, revPerUnit?: number | null, channel?: { __typename?: 'Channel', name: string } | null, product?: { __typename?: 'Product', refId: string, name: string, sku: string } | null, store?: { __typename?: 'Warehouse', name: string, id: string, refId: string } | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type ReportCartRulePerformanceQueryVariables = Exact<{
  args: FilterCartRulePerformance;
}>;


export type ReportCartRulePerformanceQuery = { __typename?: 'Query', reportCartRulePerformance: Array<{ __typename?: 'ReportCartRulePerformance', created?: any | null, total?: number | null, channelId?: string | null, channel?: { __typename?: 'Channel', name: string } | null, cartRule?: { __typename?: 'CartRule', name: string, refId: string, action: string, expires: any } | null }> };

export type ExportStaffPerformanceQueryVariables = Exact<{
  args: FilterAllStaffPerformance;
}>;


export type ExportStaffPerformanceQuery = { __typename?: 'Query', exportStaffPerformance: Array<{ __typename?: 'ReportStaffPerformance', total?: number | null, subTotal?: number | null, count?: number | null, adminId?: number | null, kpi?: number | null, admin?: { __typename?: 'AdminDto', fullname?: string | null, avatar?: string | null, username?: string | null } | null }> };

export type ExportProvincePerformanceQueryVariables = Exact<{
  args: FilterProvincePerformance;
}>;


export type ExportProvincePerformanceQuery = { __typename?: 'Query', exportProvincePerformance: Array<{ __typename?: 'ReportProvincePerformance', count?: number | null, countCompleted?: number | null, salePrice?: number | null, salePriceNet?: number | null, quantity?: number | null, province?: { __typename?: 'Provinces', name: string } | null }> };

export type SummaryReportProductPerformanceQueryVariables = Exact<{
  args: FilterProductPerformance;
}>;


export type SummaryReportProductPerformanceQuery = { __typename?: 'Query', summaryReportProductPerformance?: { __typename?: 'SummaryReportProductPerformanceDto', quantity: number, salePrice: number, salePriceNet: number, productionCost: number, saleExpense: number, adminExpense: number, netProfit: number, revPerUnit: number, includeGroupProduct?: ProductPerformanceFilterProductType | null } | null };

export type DetailReportStaffPerformanceQueryVariables = Exact<{
  args: FilterStaffPerformance;
}>;


export type DetailReportStaffPerformanceQuery = { __typename?: 'Query', detailReportStaffPerformance: { __typename?: 'ReportStaffPerformanceDetailPaginateDto', data: Array<{ __typename?: 'ReportStaffPerformanceDetail', total?: number | null, subTotal?: number | null, count?: number | null, channel?: { __typename?: 'Channel', name: string } | null, store?: { __typename?: 'Warehouse', name: string } | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type DetailReportProductPerformanceOrderQueryVariables = Exact<{
  args: FilterProductPerformance;
}>;


export type DetailReportProductPerformanceOrderQuery = { __typename?: 'Query', detailReportProductPerformanceOrder: { __typename?: 'ReportProductPerformanceOrderPaginateDto', data: Array<{ __typename?: 'ReportProductPerformanceOrder', salePrice?: number | null, discount?: number | null, salePriceNet?: number | null, quantity?: number | null, created?: any | null, order?: { __typename?: 'OrderBasic', orderId?: string | null, created?: any | null } | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type FindAllRoleQueryVariables = Exact<{
  pagination: SearchRolePaginateInput;
}>;


export type FindAllRoleQuery = { __typename?: 'Query', findAllRole: { __typename?: 'RoleAdminPaginateDto', data?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, roleId?: number | null, name?: string | null, roleType?: RoleType | null, createdAt?: any | null, updatedAt?: any | null, permissions?: Array<string> | null }> | null, pagination?: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } | null } };

export type FindOneRoleQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindOneRoleQuery = { __typename?: 'Query', findOneRole: { __typename?: 'RoleAdminDto', id?: string | null, roleId?: number | null, name?: string | null, roleType?: RoleType | null, createdAt?: any | null, updatedAt?: any | null, permissions?: Array<string> | null } };

export type FindUnassignedRoleListQueryVariables = Exact<{
  userId: Scalars['String'];
  args: SearchRolePaginateInput;
}>;


export type FindUnassignedRoleListQuery = { __typename?: 'Query', findUnassignedRole: { __typename?: 'RoleAdminPaginateDto', data?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, roleId?: number | null, name?: string | null, roleType?: RoleType | null, createdAt?: any | null, updatedAt?: any | null, permissions?: Array<string> | null }> | null, pagination?: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } | null } };

export type CreateRoleMutationVariables = Exact<{
  input: RoleAdminInputDto;
}>;


export type CreateRoleMutation = { __typename?: 'Mutation', createRole: { __typename?: 'RoleAdminDto', id?: string | null, roleId?: number | null, name?: string | null, roleType?: RoleType | null, createdAt?: any | null, updatedAt?: any | null, permissions?: Array<string> | null } };

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars['String'];
  input: RoleAdminInputDto;
}>;


export type UpdateRoleMutation = { __typename?: 'Mutation', updateRole: { __typename?: 'RoleAdminDto', id?: string | null, roleId?: number | null, name?: string | null, roleType?: RoleType | null, createdAt?: any | null, updatedAt?: any | null, permissions?: Array<string> | null } };

export type RemoveRoleMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type RemoveRoleMutation = { __typename?: 'Mutation', removeRole: { __typename?: 'DeletedResultDto', status: number } };

export type StaffListQueryVariables = Exact<{
  args: AdminStaffPaginate;
}>;


export type StaffListQuery = { __typename?: 'Query', findStaffByAdmin: { __typename?: 'AdminStaffPaginateDto', data: Array<{ __typename?: 'AdminDto', id?: string | null, refId?: string | null, name?: string | null, username?: string | null, fullname?: string | null, avatar?: string | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type CreateUserByAdminMutationVariables = Exact<{
  input: AdminInputDto;
}>;


export type CreateUserByAdminMutation = { __typename?: 'Mutation', createUserByAdmin: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type UpdateUserByAdminMutationVariables = Exact<{
  input: AdminInputDto;
}>;


export type UpdateUserByAdminMutation = { __typename?: 'Mutation', updateUserByAdmin: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type FindUsersByAdminQueryVariables = Exact<{
  pagination: AdminPaginate;
}>;


export type FindUsersByAdminQuery = { __typename?: 'Query', findUsersByAdmin: { __typename?: 'AdminPaginateDto', data: Array<{ __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export type FindUserByAdminQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FindUserByAdminQuery = { __typename?: 'Query', findUserByAdmin: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type DeleteUsersByAdminMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteUsersByAdminMutation = { __typename?: 'Mutation', deleteUsersByAdmin: { __typename?: 'Admin', id: string } };

export type RemoveUserChannelMutationVariables = Exact<{
  input: AdminInputDto;
}>;


export type RemoveUserChannelMutation = { __typename?: 'Mutation', removeUserChannel: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type RemoveUserRoleMutationVariables = Exact<{
  input: AdminInputDto;
}>;


export type RemoveUserRoleMutation = { __typename?: 'Mutation', removeUserRole: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type DuplicateUserByAdminMutationVariables = Exact<{
  input: Scalars['String'];
}>;


export type DuplicateUserByAdminMutation = { __typename?: 'Mutation', duplicateUserByAdmin: { __typename?: 'Admin', id: string, fullName?: string | null, avatar?: string | null, email?: string | null, accountAccess: boolean, companyId?: string | null, nickName?: string | null, createdAt?: any | null, updatedAt?: any | null, userId?: number | null, hasTwoFaAuthentication?: boolean | null, codeTwoFaAuthentication?: string | null, qrCodeTwoFaAuthentication?: string | null, userName?: string | null, employeeId?: string | null, phoneStatus?: PhoneStatus | null, phoneNumber?: string | null, channels?: Array<{ __typename?: 'Channel', id: string, name: string }> | null, roles?: Array<{ __typename?: 'RoleAdminDto', id?: string | null, name?: string | null, roleId?: number | null, permissions?: Array<string> | null }> | null } };

export type WarehouseListQueryVariables = Exact<{
  args: WarehousePaginate;
}>;


export type WarehouseListQuery = { __typename?: 'Query', findWarehousesByAdmin: { __typename?: 'WarehousePaginateDto', data: Array<{ __typename?: 'Warehouse', id: string, refId: string, name: string }>, pagination: { __typename?: 'PaginateDto', currentPage?: number | null, limit?: number | null, offset?: number | null, total?: number | null, items?: number | null } } };

export const F_Auth_Admin_ResponseFragmentDoc = gql`
    fragment F_AUTH_ADMIN_RESPONSE on AdminAuthDto {
  accessToken
  id
  fullName
  avatar
  email
  error
  companyId
  phoneStatus
  phoneNumber
  hasVerifyOtp
  roles {
    id
    name
    permissions
  }
}
    `;
export const F_PaginationFragmentDoc = gql`
    fragment F_PAGINATION on PaginateDto {
  currentPage
  limit
  offset
  total
  items
}
    `;
export const F_ChannelFragmentDoc = gql`
    fragment F_CHANNEL on Channel {
  id
  refId
  name
  createdAt
  updatedAt
  orderSource
}
    `;
export const F_WarehouseFragmentDoc = gql`
    fragment F_WAREHOUSE on Warehouse {
  id
  refId
  name
}
    `;
export const F_RoleFragmentDoc = gql`
    fragment F_ROLE on RoleAdminDto {
  id
  roleId
  name
  roleType
  createdAt
  updatedAt
  permissions
}
    `;
export const F_Report_PerformanceFragmentDoc = gql`
    fragment F_REPORT_PERFORMANCE on ReportChannelPerformance {
  created
  total
  channelId
}
    `;
export const F_Report_Total_SaleFragmentDoc = gql`
    fragment F_REPORT_TOTAL_SALE on ReportTotalSales {
  created
  total
}
    `;
export const F_Report_StoreFragmentDoc = gql`
    fragment F_REPORT_STORE on ReportStorePerformance {
  created
  total
  store {
    name
    refId
  }
}
    `;
export const F_Report_Store_GroupedFragmentDoc = gql`
    fragment F_REPORT_STORE_GROUPED on ReportStorePerformanceGrouped {
  count
  store {
    name
    refId
  }
  report {
    total
    created
  }
}
    `;
export const F_Report_StaffFragmentDoc = gql`
    fragment F_REPORT_STAFF on ReportStaffPerformance {
  total
  subTotal
  count
  adminId
  kpi
  admin {
    fullname
    avatar
    username
  }
}
    `;
export const F_Report_ProvinceFragmentDoc = gql`
    fragment F_REPORT_PROVINCE on ReportProvincePerformance {
  count
  countCompleted
  salePrice
  salePriceNet
  quantity
  province {
    name
  }
}
    `;
export const F_CategoryFragmentDoc = gql`
    fragment F_CATEGORY on Category {
  id
  refId
  name
}
    `;
export const F_ProductFragmentDoc = gql`
    fragment F_PRODUCT on Product {
  refId
  name
  sku
}
    `;
export const F_BrandFragmentDoc = gql`
    fragment F_BRAND on Brand {
  id
  refId
  name
}
    `;
export const F_ProvinceFragmentDoc = gql`
    fragment F_PROVINCE on Provinces {
  id
  refId
  name
}
    `;
export const F_StaffFragmentDoc = gql`
    fragment F_STAFF on AdminDto {
  id
  refId
  name
  username
  fullname
  avatar
}
    `;
export const F_Report_ProductFragmentDoc = gql`
    fragment F_REPORT_PRODUCT on ReportProductPerformance {
  created
  total
  channelId
  storeId
  brandRefId
  product {
    refId
    name
    sku
  }
  quantity
  salePrice
  salePriceNet
  categoriesRefId
  productionCost
  saleExpense
  adminExpense
  netProfit
  revPerUnit
}
    `;
export const F_Report_Product_DetailFragmentDoc = gql`
    fragment F_REPORT_PRODUCT_DETAIL on ReportProductPerformance {
  created
  total
  channel {
    name
  }
  product {
    refId
    name
    sku
  }
  store {
    name
    id
    refId
  }
  quantity
  salePrice
  salePriceNet
  productionCost
  saleExpense
  adminExpense
  totalOrder
  netProfit
  revPerUnit
}
    `;
export const F_Report_Product_OrderFragmentDoc = gql`
    fragment F_REPORT_PRODUCT_ORDER on ReportProductPerformanceOrder {
  order {
    orderId
    created
  }
  salePrice
  discount
  salePriceNet
  quantity
  created
}
    `;
export const F_Report_Cartrule_PerformanceFragmentDoc = gql`
    fragment F_REPORT_CARTRULE_PERFORMANCE on ReportCartRulePerformance {
  created
  total
  channelId
  channel {
    name
  }
  cartRule {
    name
    refId
    action
    expires
  }
}
    `;
export const F_Cart_RuleFragmentDoc = gql`
    fragment F_CART_RULE on CartRule {
  id
  refId
  name
  action
  channelId
}
    `;
export const F_Report_Staff_DetailFragmentDoc = gql`
    fragment F_REPORT_STAFF_DETAIL on ReportStaffPerformanceDetail {
  total
  subTotal
  channel {
    name
  }
  store {
    name
  }
  count
}
    `;
export const F_UserFragmentDoc = gql`
    fragment F_USER on Admin {
  id
  fullName
  avatar
  email
  channels {
    id
    name
  }
  roles {
    id
    name
    roleId
    permissions
  }
  accountAccess
  companyId
  nickName
  createdAt
  updatedAt
  userId
  hasTwoFaAuthentication
  codeTwoFaAuthentication
  qrCodeTwoFaAuthentication
  userName
  employeeId
  phoneStatus
  phoneNumber
}
    `;
export const AdminLoginDocument = gql`
    mutation adminLogin($args: AuthInputDto!) {
  loginByAdmin(args: $args) {
    ...F_AUTH_ADMIN_RESPONSE
  }
}
    ${F_Auth_Admin_ResponseFragmentDoc}`;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const GetMyInfoDocument = gql`
    query getMyInfo {
  profileForAdmin {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;

/**
 * __useGetMyInfoQuery__
 *
 * To run a query within a React component, call `useGetMyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, options);
      }
export function useGetMyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, options);
        }
export type GetMyInfoQueryHookResult = ReturnType<typeof useGetMyInfoQuery>;
export type GetMyInfoLazyQueryHookResult = ReturnType<typeof useGetMyInfoLazyQuery>;
export type GetMyInfoQueryResult = Apollo.QueryResult<GetMyInfoQuery, GetMyInfoQueryVariables>;
export const RandomTwoFaAuthenticationDocument = gql`
    query randomTwoFaAuthentication($nickName: String!) {
  randomTwoFaAuthentication(nickName: $nickName) {
    codeTwoFaAuthentication
    qrCodeTwoFaAuthentication
  }
}
    `;

/**
 * __useRandomTwoFaAuthenticationQuery__
 *
 * To run a query within a React component, call `useRandomTwoFaAuthenticationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRandomTwoFaAuthenticationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRandomTwoFaAuthenticationQuery({
 *   variables: {
 *      nickName: // value for 'nickName'
 *   },
 * });
 */
export function useRandomTwoFaAuthenticationQuery(baseOptions: Apollo.QueryHookOptions<RandomTwoFaAuthenticationQuery, RandomTwoFaAuthenticationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RandomTwoFaAuthenticationQuery, RandomTwoFaAuthenticationQueryVariables>(RandomTwoFaAuthenticationDocument, options);
      }
export function useRandomTwoFaAuthenticationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RandomTwoFaAuthenticationQuery, RandomTwoFaAuthenticationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RandomTwoFaAuthenticationQuery, RandomTwoFaAuthenticationQueryVariables>(RandomTwoFaAuthenticationDocument, options);
        }
export type RandomTwoFaAuthenticationQueryHookResult = ReturnType<typeof useRandomTwoFaAuthenticationQuery>;
export type RandomTwoFaAuthenticationLazyQueryHookResult = ReturnType<typeof useRandomTwoFaAuthenticationLazyQuery>;
export type RandomTwoFaAuthenticationQueryResult = Apollo.QueryResult<RandomTwoFaAuthenticationQuery, RandomTwoFaAuthenticationQueryVariables>;
export const LoginByWorkInfinityDocument = gql`
    mutation loginByWorkInfinity($code: String!) {
  loginByWorkInfinity(code: $code) {
    ...F_AUTH_ADMIN_RESPONSE
  }
}
    ${F_Auth_Admin_ResponseFragmentDoc}`;
export type LoginByWorkInfinityMutationFn = Apollo.MutationFunction<LoginByWorkInfinityMutation, LoginByWorkInfinityMutationVariables>;

/**
 * __useLoginByWorkInfinityMutation__
 *
 * To run a mutation, you first call `useLoginByWorkInfinityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByWorkInfinityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByWorkInfinityMutation, { data, loading, error }] = useLoginByWorkInfinityMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginByWorkInfinityMutation(baseOptions?: Apollo.MutationHookOptions<LoginByWorkInfinityMutation, LoginByWorkInfinityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByWorkInfinityMutation, LoginByWorkInfinityMutationVariables>(LoginByWorkInfinityDocument, options);
      }
export type LoginByWorkInfinityMutationHookResult = ReturnType<typeof useLoginByWorkInfinityMutation>;
export type LoginByWorkInfinityMutationResult = Apollo.MutationResult<LoginByWorkInfinityMutation>;
export type LoginByWorkInfinityMutationOptions = Apollo.BaseMutationOptions<LoginByWorkInfinityMutation, LoginByWorkInfinityMutationVariables>;
export const SendOtpVerifyPhoneNumberByAdminDocument = gql`
    query sendOTPVerifyPhoneNumberByAdmin($input: AdminInputOTPVerifyPhoneNumberDto!) {
  sendOTPVerifyPhoneNumberByAdmin(input: $input) {
    token
  }
}
    `;

/**
 * __useSendOtpVerifyPhoneNumberByAdminQuery__
 *
 * To run a query within a React component, call `useSendOtpVerifyPhoneNumberByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendOtpVerifyPhoneNumberByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendOtpVerifyPhoneNumberByAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOtpVerifyPhoneNumberByAdminQuery(baseOptions: Apollo.QueryHookOptions<SendOtpVerifyPhoneNumberByAdminQuery, SendOtpVerifyPhoneNumberByAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendOtpVerifyPhoneNumberByAdminQuery, SendOtpVerifyPhoneNumberByAdminQueryVariables>(SendOtpVerifyPhoneNumberByAdminDocument, options);
      }
export function useSendOtpVerifyPhoneNumberByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendOtpVerifyPhoneNumberByAdminQuery, SendOtpVerifyPhoneNumberByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendOtpVerifyPhoneNumberByAdminQuery, SendOtpVerifyPhoneNumberByAdminQueryVariables>(SendOtpVerifyPhoneNumberByAdminDocument, options);
        }
export type SendOtpVerifyPhoneNumberByAdminQueryHookResult = ReturnType<typeof useSendOtpVerifyPhoneNumberByAdminQuery>;
export type SendOtpVerifyPhoneNumberByAdminLazyQueryHookResult = ReturnType<typeof useSendOtpVerifyPhoneNumberByAdminLazyQuery>;
export type SendOtpVerifyPhoneNumberByAdminQueryResult = Apollo.QueryResult<SendOtpVerifyPhoneNumberByAdminQuery, SendOtpVerifyPhoneNumberByAdminQueryVariables>;
export const VerifyPhoneNumberByAdminDocument = gql`
    mutation verifyPhoneNumberByAdmin($input: AdminInputVerifyPhoneNumberDto!) {
  verifyPhoneNumberByAdmin(input: $input) {
    accessToken
  }
}
    `;
export type VerifyPhoneNumberByAdminMutationFn = Apollo.MutationFunction<VerifyPhoneNumberByAdminMutation, VerifyPhoneNumberByAdminMutationVariables>;

/**
 * __useVerifyPhoneNumberByAdminMutation__
 *
 * To run a mutation, you first call `useVerifyPhoneNumberByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyPhoneNumberByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyPhoneNumberByAdminMutation, { data, loading, error }] = useVerifyPhoneNumberByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyPhoneNumberByAdminMutation(baseOptions?: Apollo.MutationHookOptions<VerifyPhoneNumberByAdminMutation, VerifyPhoneNumberByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyPhoneNumberByAdminMutation, VerifyPhoneNumberByAdminMutationVariables>(VerifyPhoneNumberByAdminDocument, options);
      }
export type VerifyPhoneNumberByAdminMutationHookResult = ReturnType<typeof useVerifyPhoneNumberByAdminMutation>;
export type VerifyPhoneNumberByAdminMutationResult = Apollo.MutationResult<VerifyPhoneNumberByAdminMutation>;
export type VerifyPhoneNumberByAdminMutationOptions = Apollo.BaseMutationOptions<VerifyPhoneNumberByAdminMutation, VerifyPhoneNumberByAdminMutationVariables>;
export const SendOtpVerifyPhoneNumberUsersByAdminDocument = gql`
    query sendOTPVerifyPhoneNumberUsersByAdmin($input: AdminInputOTPVerifyPhoneNumberUserDto!) {
  sendOTPVerifyPhoneNumberUsersByAdmin(input: $input) {
    token
  }
}
    `;

/**
 * __useSendOtpVerifyPhoneNumberUsersByAdminQuery__
 *
 * To run a query within a React component, call `useSendOtpVerifyPhoneNumberUsersByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendOtpVerifyPhoneNumberUsersByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendOtpVerifyPhoneNumberUsersByAdminQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendOtpVerifyPhoneNumberUsersByAdminQuery(baseOptions: Apollo.QueryHookOptions<SendOtpVerifyPhoneNumberUsersByAdminQuery, SendOtpVerifyPhoneNumberUsersByAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendOtpVerifyPhoneNumberUsersByAdminQuery, SendOtpVerifyPhoneNumberUsersByAdminQueryVariables>(SendOtpVerifyPhoneNumberUsersByAdminDocument, options);
      }
export function useSendOtpVerifyPhoneNumberUsersByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendOtpVerifyPhoneNumberUsersByAdminQuery, SendOtpVerifyPhoneNumberUsersByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendOtpVerifyPhoneNumberUsersByAdminQuery, SendOtpVerifyPhoneNumberUsersByAdminQueryVariables>(SendOtpVerifyPhoneNumberUsersByAdminDocument, options);
        }
export type SendOtpVerifyPhoneNumberUsersByAdminQueryHookResult = ReturnType<typeof useSendOtpVerifyPhoneNumberUsersByAdminQuery>;
export type SendOtpVerifyPhoneNumberUsersByAdminLazyQueryHookResult = ReturnType<typeof useSendOtpVerifyPhoneNumberUsersByAdminLazyQuery>;
export type SendOtpVerifyPhoneNumberUsersByAdminQueryResult = Apollo.QueryResult<SendOtpVerifyPhoneNumberUsersByAdminQuery, SendOtpVerifyPhoneNumberUsersByAdminQueryVariables>;
export const UpdatePhoneNumberUsersByAdminDocument = gql`
    mutation updatePhoneNumberUsersByAdmin($id: String!, $input: AdminInputUpdatePhoneNumberUserDto!) {
  updatePhoneNumberUsersByAdmin(id: $id, input: $input) {
    id
    phoneNumber
    phoneStatus
  }
}
    `;
export type UpdatePhoneNumberUsersByAdminMutationFn = Apollo.MutationFunction<UpdatePhoneNumberUsersByAdminMutation, UpdatePhoneNumberUsersByAdminMutationVariables>;

/**
 * __useUpdatePhoneNumberUsersByAdminMutation__
 *
 * To run a mutation, you first call `useUpdatePhoneNumberUsersByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhoneNumberUsersByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhoneNumberUsersByAdminMutation, { data, loading, error }] = useUpdatePhoneNumberUsersByAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePhoneNumberUsersByAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhoneNumberUsersByAdminMutation, UpdatePhoneNumberUsersByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhoneNumberUsersByAdminMutation, UpdatePhoneNumberUsersByAdminMutationVariables>(UpdatePhoneNumberUsersByAdminDocument, options);
      }
export type UpdatePhoneNumberUsersByAdminMutationHookResult = ReturnType<typeof useUpdatePhoneNumberUsersByAdminMutation>;
export type UpdatePhoneNumberUsersByAdminMutationResult = Apollo.MutationResult<UpdatePhoneNumberUsersByAdminMutation>;
export type UpdatePhoneNumberUsersByAdminMutationOptions = Apollo.BaseMutationOptions<UpdatePhoneNumberUsersByAdminMutation, UpdatePhoneNumberUsersByAdminMutationVariables>;
export const BrandListDocument = gql`
    query brandList($args: BrandPaginate!) {
  findBrandByAdmin(paginate: $args) {
    data {
      ...F_BRAND
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_BrandFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useBrandListQuery__
 *
 * To run a query within a React component, call `useBrandListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useBrandListQuery(baseOptions: Apollo.QueryHookOptions<BrandListQuery, BrandListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandListQuery, BrandListQueryVariables>(BrandListDocument, options);
      }
export function useBrandListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandListQuery, BrandListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandListQuery, BrandListQueryVariables>(BrandListDocument, options);
        }
export type BrandListQueryHookResult = ReturnType<typeof useBrandListQuery>;
export type BrandListLazyQueryHookResult = ReturnType<typeof useBrandListLazyQuery>;
export type BrandListQueryResult = Apollo.QueryResult<BrandListQuery, BrandListQueryVariables>;
export const CartRuleListDocument = gql`
    query cartRuleList($args: CartRulePaginate!) {
  findCartRuleByAdmin(paginate: $args) {
    data {
      ...F_CART_RULE
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_Cart_RuleFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useCartRuleListQuery__
 *
 * To run a query within a React component, call `useCartRuleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCartRuleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCartRuleListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCartRuleListQuery(baseOptions: Apollo.QueryHookOptions<CartRuleListQuery, CartRuleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CartRuleListQuery, CartRuleListQueryVariables>(CartRuleListDocument, options);
      }
export function useCartRuleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CartRuleListQuery, CartRuleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CartRuleListQuery, CartRuleListQueryVariables>(CartRuleListDocument, options);
        }
export type CartRuleListQueryHookResult = ReturnType<typeof useCartRuleListQuery>;
export type CartRuleListLazyQueryHookResult = ReturnType<typeof useCartRuleListLazyQuery>;
export type CartRuleListQueryResult = Apollo.QueryResult<CartRuleListQuery, CartRuleListQueryVariables>;
export const CategoryListDocument = gql`
    query categoryList($args: CategoriesPaginate!) {
  findCategoriesByAdmin(paginate: $args) {
    data {
      ...F_CATEGORY
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_CategoryFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions: Apollo.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
      }
export function useCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const ChannelListDocument = gql`
    query channelList($args: ChannelPaginate!) {
  findChannelsByAdmin(paginate: $args) {
    data {
      ...F_CHANNEL
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_ChannelFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useChannelListQuery__
 *
 * To run a query within a React component, call `useChannelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useChannelListQuery(baseOptions: Apollo.QueryHookOptions<ChannelListQuery, ChannelListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelListQuery, ChannelListQueryVariables>(ChannelListDocument, options);
      }
export function useChannelListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelListQuery, ChannelListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelListQuery, ChannelListQueryVariables>(ChannelListDocument, options);
        }
export type ChannelListQueryHookResult = ReturnType<typeof useChannelListQuery>;
export type ChannelListLazyQueryHookResult = ReturnType<typeof useChannelListLazyQuery>;
export type ChannelListQueryResult = Apollo.QueryResult<ChannelListQuery, ChannelListQueryVariables>;
export const UnassignedChannelListDocument = gql`
    query unassignedChannelList($userId: String!, $args: ChannelPaginate!) {
  findUnassignedChannel(userId: $userId, paginate: $args) {
    data {
      ...F_CHANNEL
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_ChannelFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useUnassignedChannelListQuery__
 *
 * To run a query within a React component, call `useUnassignedChannelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnassignedChannelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnassignedChannelListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useUnassignedChannelListQuery(baseOptions: Apollo.QueryHookOptions<UnassignedChannelListQuery, UnassignedChannelListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnassignedChannelListQuery, UnassignedChannelListQueryVariables>(UnassignedChannelListDocument, options);
      }
export function useUnassignedChannelListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnassignedChannelListQuery, UnassignedChannelListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnassignedChannelListQuery, UnassignedChannelListQueryVariables>(UnassignedChannelListDocument, options);
        }
export type UnassignedChannelListQueryHookResult = ReturnType<typeof useUnassignedChannelListQuery>;
export type UnassignedChannelListLazyQueryHookResult = ReturnType<typeof useUnassignedChannelListLazyQuery>;
export type UnassignedChannelListQueryResult = Apollo.QueryResult<UnassignedChannelListQuery, UnassignedChannelListQueryVariables>;
export const FindProductDocument = gql`
    query findProduct($args: ProductPaginate!) {
  findProduct(paginate: $args) {
    data {
      ...F_PRODUCT
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_ProductFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useFindProductQuery__
 *
 * To run a query within a React component, call `useFindProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindProductQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useFindProductQuery(baseOptions: Apollo.QueryHookOptions<FindProductQuery, FindProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, options);
      }
export function useFindProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindProductQuery, FindProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, options);
        }
export type FindProductQueryHookResult = ReturnType<typeof useFindProductQuery>;
export type FindProductLazyQueryHookResult = ReturnType<typeof useFindProductLazyQuery>;
export type FindProductQueryResult = Apollo.QueryResult<FindProductQuery, FindProductQueryVariables>;
export const ProvinceListDocument = gql`
    query provinceList($args: LocationPaginate!) {
  findProvincesByAdmin(paginate: $args) {
    data {
      ...F_PROVINCE
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_ProvinceFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useProvinceListQuery__
 *
 * To run a query within a React component, call `useProvinceListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProvinceListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProvinceListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useProvinceListQuery(baseOptions: Apollo.QueryHookOptions<ProvinceListQuery, ProvinceListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProvinceListQuery, ProvinceListQueryVariables>(ProvinceListDocument, options);
      }
export function useProvinceListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProvinceListQuery, ProvinceListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProvinceListQuery, ProvinceListQueryVariables>(ProvinceListDocument, options);
        }
export type ProvinceListQueryHookResult = ReturnType<typeof useProvinceListQuery>;
export type ProvinceListLazyQueryHookResult = ReturnType<typeof useProvinceListLazyQuery>;
export type ProvinceListQueryResult = Apollo.QueryResult<ProvinceListQuery, ProvinceListQueryVariables>;
export const ReportChannelPerformanceDocument = gql`
    query reportChannelPerformance($args: FilterChannelPerformance!) {
  reportChannelPerformance(payload: $args) {
    ...F_REPORT_PERFORMANCE
  }
}
    ${F_Report_PerformanceFragmentDoc}`;

/**
 * __useReportChannelPerformanceQuery__
 *
 * To run a query within a React component, call `useReportChannelPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportChannelPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportChannelPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportChannelPerformanceQuery(baseOptions: Apollo.QueryHookOptions<ReportChannelPerformanceQuery, ReportChannelPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportChannelPerformanceQuery, ReportChannelPerformanceQueryVariables>(ReportChannelPerformanceDocument, options);
      }
export function useReportChannelPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportChannelPerformanceQuery, ReportChannelPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportChannelPerformanceQuery, ReportChannelPerformanceQueryVariables>(ReportChannelPerformanceDocument, options);
        }
export type ReportChannelPerformanceQueryHookResult = ReturnType<typeof useReportChannelPerformanceQuery>;
export type ReportChannelPerformanceLazyQueryHookResult = ReturnType<typeof useReportChannelPerformanceLazyQuery>;
export type ReportChannelPerformanceQueryResult = Apollo.QueryResult<ReportChannelPerformanceQuery, ReportChannelPerformanceQueryVariables>;
export const ReportTotalSalesDocument = gql`
    query reportTotalSales($args: FilterTotalSales!) {
  reportTotalSales(payload: $args) {
    ...F_REPORT_TOTAL_SALE
  }
}
    ${F_Report_Total_SaleFragmentDoc}`;

/**
 * __useReportTotalSalesQuery__
 *
 * To run a query within a React component, call `useReportTotalSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTotalSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTotalSalesQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportTotalSalesQuery(baseOptions: Apollo.QueryHookOptions<ReportTotalSalesQuery, ReportTotalSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportTotalSalesQuery, ReportTotalSalesQueryVariables>(ReportTotalSalesDocument, options);
      }
export function useReportTotalSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportTotalSalesQuery, ReportTotalSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportTotalSalesQuery, ReportTotalSalesQueryVariables>(ReportTotalSalesDocument, options);
        }
export type ReportTotalSalesQueryHookResult = ReturnType<typeof useReportTotalSalesQuery>;
export type ReportTotalSalesLazyQueryHookResult = ReturnType<typeof useReportTotalSalesLazyQuery>;
export type ReportTotalSalesQueryResult = Apollo.QueryResult<ReportTotalSalesQuery, ReportTotalSalesQueryVariables>;
export const ReportStorePerformanceDocument = gql`
    query reportStorePerformance($args: FilterStorePerformance!) {
  reportStorePerformance(payload: $args) {
    ...F_REPORT_STORE
  }
}
    ${F_Report_StoreFragmentDoc}`;

/**
 * __useReportStorePerformanceQuery__
 *
 * To run a query within a React component, call `useReportStorePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportStorePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportStorePerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportStorePerformanceQuery(baseOptions: Apollo.QueryHookOptions<ReportStorePerformanceQuery, ReportStorePerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportStorePerformanceQuery, ReportStorePerformanceQueryVariables>(ReportStorePerformanceDocument, options);
      }
export function useReportStorePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportStorePerformanceQuery, ReportStorePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportStorePerformanceQuery, ReportStorePerformanceQueryVariables>(ReportStorePerformanceDocument, options);
        }
export type ReportStorePerformanceQueryHookResult = ReturnType<typeof useReportStorePerformanceQuery>;
export type ReportStorePerformanceLazyQueryHookResult = ReturnType<typeof useReportStorePerformanceLazyQuery>;
export type ReportStorePerformanceQueryResult = Apollo.QueryResult<ReportStorePerformanceQuery, ReportStorePerformanceQueryVariables>;
export const ReportStorePerformanceByStoreDocument = gql`
    query reportStorePerformanceByStore($args: FilterProductPerformancePaging!) {
  reportStorePerformanceByStore(payload: $args) {
    data {
      ...F_REPORT_STORE_GROUPED
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_Report_Store_GroupedFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useReportStorePerformanceByStoreQuery__
 *
 * To run a query within a React component, call `useReportStorePerformanceByStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportStorePerformanceByStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportStorePerformanceByStoreQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportStorePerformanceByStoreQuery(baseOptions: Apollo.QueryHookOptions<ReportStorePerformanceByStoreQuery, ReportStorePerformanceByStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportStorePerformanceByStoreQuery, ReportStorePerformanceByStoreQueryVariables>(ReportStorePerformanceByStoreDocument, options);
      }
export function useReportStorePerformanceByStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportStorePerformanceByStoreQuery, ReportStorePerformanceByStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportStorePerformanceByStoreQuery, ReportStorePerformanceByStoreQueryVariables>(ReportStorePerformanceByStoreDocument, options);
        }
export type ReportStorePerformanceByStoreQueryHookResult = ReturnType<typeof useReportStorePerformanceByStoreQuery>;
export type ReportStorePerformanceByStoreLazyQueryHookResult = ReturnType<typeof useReportStorePerformanceByStoreLazyQuery>;
export type ReportStorePerformanceByStoreQueryResult = Apollo.QueryResult<ReportStorePerformanceByStoreQuery, ReportStorePerformanceByStoreQueryVariables>;
export const ReportProductPerformanceDocument = gql`
    query reportProductPerformance($args: FilterProductPerformance!) {
  reportProductPerformance(payload: $args) {
    data {
      ...F_REPORT_PRODUCT
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_Report_ProductFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useReportProductPerformanceQuery__
 *
 * To run a query within a React component, call `useReportProductPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportProductPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportProductPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportProductPerformanceQuery(baseOptions: Apollo.QueryHookOptions<ReportProductPerformanceQuery, ReportProductPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportProductPerformanceQuery, ReportProductPerformanceQueryVariables>(ReportProductPerformanceDocument, options);
      }
export function useReportProductPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportProductPerformanceQuery, ReportProductPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportProductPerformanceQuery, ReportProductPerformanceQueryVariables>(ReportProductPerformanceDocument, options);
        }
export type ReportProductPerformanceQueryHookResult = ReturnType<typeof useReportProductPerformanceQuery>;
export type ReportProductPerformanceLazyQueryHookResult = ReturnType<typeof useReportProductPerformanceLazyQuery>;
export type ReportProductPerformanceQueryResult = Apollo.QueryResult<ReportProductPerformanceQuery, ReportProductPerformanceQueryVariables>;
export const ExportReportProductPerformanceDocument = gql`
    query exportReportProductPerformance($args: FilterProductPerformance!) {
  exportReportProductPerformance(payload: $args) {
    ...F_REPORT_PRODUCT
  }
}
    ${F_Report_ProductFragmentDoc}`;

/**
 * __useExportReportProductPerformanceQuery__
 *
 * To run a query within a React component, call `useExportReportProductPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportReportProductPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportReportProductPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useExportReportProductPerformanceQuery(baseOptions: Apollo.QueryHookOptions<ExportReportProductPerformanceQuery, ExportReportProductPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportReportProductPerformanceQuery, ExportReportProductPerformanceQueryVariables>(ExportReportProductPerformanceDocument, options);
      }
export function useExportReportProductPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportReportProductPerformanceQuery, ExportReportProductPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportReportProductPerformanceQuery, ExportReportProductPerformanceQueryVariables>(ExportReportProductPerformanceDocument, options);
        }
export type ExportReportProductPerformanceQueryHookResult = ReturnType<typeof useExportReportProductPerformanceQuery>;
export type ExportReportProductPerformanceLazyQueryHookResult = ReturnType<typeof useExportReportProductPerformanceLazyQuery>;
export type ExportReportProductPerformanceQueryResult = Apollo.QueryResult<ExportReportProductPerformanceQuery, ExportReportProductPerformanceQueryVariables>;
export const DetailReportProductPerformanceDocument = gql`
    query detailReportProductPerformance($args: FilterProductPerformance!) {
  detailReportProductPerformance(payload: $args) {
    data {
      ...F_REPORT_PRODUCT_DETAIL
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_Report_Product_DetailFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useDetailReportProductPerformanceQuery__
 *
 * To run a query within a React component, call `useDetailReportProductPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailReportProductPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailReportProductPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDetailReportProductPerformanceQuery(baseOptions: Apollo.QueryHookOptions<DetailReportProductPerformanceQuery, DetailReportProductPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailReportProductPerformanceQuery, DetailReportProductPerformanceQueryVariables>(DetailReportProductPerformanceDocument, options);
      }
export function useDetailReportProductPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailReportProductPerformanceQuery, DetailReportProductPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailReportProductPerformanceQuery, DetailReportProductPerformanceQueryVariables>(DetailReportProductPerformanceDocument, options);
        }
export type DetailReportProductPerformanceQueryHookResult = ReturnType<typeof useDetailReportProductPerformanceQuery>;
export type DetailReportProductPerformanceLazyQueryHookResult = ReturnType<typeof useDetailReportProductPerformanceLazyQuery>;
export type DetailReportProductPerformanceQueryResult = Apollo.QueryResult<DetailReportProductPerformanceQuery, DetailReportProductPerformanceQueryVariables>;
export const ReportCartRulePerformanceDocument = gql`
    query reportCartRulePerformance($args: FilterCartRulePerformance!) {
  reportCartRulePerformance(payload: $args) {
    ...F_REPORT_CARTRULE_PERFORMANCE
  }
}
    ${F_Report_Cartrule_PerformanceFragmentDoc}`;

/**
 * __useReportCartRulePerformanceQuery__
 *
 * To run a query within a React component, call `useReportCartRulePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportCartRulePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportCartRulePerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useReportCartRulePerformanceQuery(baseOptions: Apollo.QueryHookOptions<ReportCartRulePerformanceQuery, ReportCartRulePerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportCartRulePerformanceQuery, ReportCartRulePerformanceQueryVariables>(ReportCartRulePerformanceDocument, options);
      }
export function useReportCartRulePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportCartRulePerformanceQuery, ReportCartRulePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportCartRulePerformanceQuery, ReportCartRulePerformanceQueryVariables>(ReportCartRulePerformanceDocument, options);
        }
export type ReportCartRulePerformanceQueryHookResult = ReturnType<typeof useReportCartRulePerformanceQuery>;
export type ReportCartRulePerformanceLazyQueryHookResult = ReturnType<typeof useReportCartRulePerformanceLazyQuery>;
export type ReportCartRulePerformanceQueryResult = Apollo.QueryResult<ReportCartRulePerformanceQuery, ReportCartRulePerformanceQueryVariables>;
export const ExportStaffPerformanceDocument = gql`
    query exportStaffPerformance($args: FilterAllStaffPerformance!) {
  exportStaffPerformance(payload: $args) {
    ...F_REPORT_STAFF
  }
}
    ${F_Report_StaffFragmentDoc}`;

/**
 * __useExportStaffPerformanceQuery__
 *
 * To run a query within a React component, call `useExportStaffPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportStaffPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportStaffPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useExportStaffPerformanceQuery(baseOptions: Apollo.QueryHookOptions<ExportStaffPerformanceQuery, ExportStaffPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportStaffPerformanceQuery, ExportStaffPerformanceQueryVariables>(ExportStaffPerformanceDocument, options);
      }
export function useExportStaffPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportStaffPerformanceQuery, ExportStaffPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportStaffPerformanceQuery, ExportStaffPerformanceQueryVariables>(ExportStaffPerformanceDocument, options);
        }
export type ExportStaffPerformanceQueryHookResult = ReturnType<typeof useExportStaffPerformanceQuery>;
export type ExportStaffPerformanceLazyQueryHookResult = ReturnType<typeof useExportStaffPerformanceLazyQuery>;
export type ExportStaffPerformanceQueryResult = Apollo.QueryResult<ExportStaffPerformanceQuery, ExportStaffPerformanceQueryVariables>;
export const ExportProvincePerformanceDocument = gql`
    query exportProvincePerformance($args: FilterProvincePerformance!) {
  exportProvincePerformance(payload: $args) {
    ...F_REPORT_PROVINCE
  }
}
    ${F_Report_ProvinceFragmentDoc}`;

/**
 * __useExportProvincePerformanceQuery__
 *
 * To run a query within a React component, call `useExportProvincePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportProvincePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportProvincePerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useExportProvincePerformanceQuery(baseOptions: Apollo.QueryHookOptions<ExportProvincePerformanceQuery, ExportProvincePerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportProvincePerformanceQuery, ExportProvincePerformanceQueryVariables>(ExportProvincePerformanceDocument, options);
      }
export function useExportProvincePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportProvincePerformanceQuery, ExportProvincePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportProvincePerformanceQuery, ExportProvincePerformanceQueryVariables>(ExportProvincePerformanceDocument, options);
        }
export type ExportProvincePerformanceQueryHookResult = ReturnType<typeof useExportProvincePerformanceQuery>;
export type ExportProvincePerformanceLazyQueryHookResult = ReturnType<typeof useExportProvincePerformanceLazyQuery>;
export type ExportProvincePerformanceQueryResult = Apollo.QueryResult<ExportProvincePerformanceQuery, ExportProvincePerformanceQueryVariables>;
export const SummaryReportProductPerformanceDocument = gql`
    query summaryReportProductPerformance($args: FilterProductPerformance!) {
  summaryReportProductPerformance(payload: $args) {
    quantity
    salePrice
    salePriceNet
    productionCost
    saleExpense
    adminExpense
    netProfit
    revPerUnit
    includeGroupProduct
  }
}
    `;

/**
 * __useSummaryReportProductPerformanceQuery__
 *
 * To run a query within a React component, call `useSummaryReportProductPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryReportProductPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryReportProductPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useSummaryReportProductPerformanceQuery(baseOptions: Apollo.QueryHookOptions<SummaryReportProductPerformanceQuery, SummaryReportProductPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummaryReportProductPerformanceQuery, SummaryReportProductPerformanceQueryVariables>(SummaryReportProductPerformanceDocument, options);
      }
export function useSummaryReportProductPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryReportProductPerformanceQuery, SummaryReportProductPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummaryReportProductPerformanceQuery, SummaryReportProductPerformanceQueryVariables>(SummaryReportProductPerformanceDocument, options);
        }
export type SummaryReportProductPerformanceQueryHookResult = ReturnType<typeof useSummaryReportProductPerformanceQuery>;
export type SummaryReportProductPerformanceLazyQueryHookResult = ReturnType<typeof useSummaryReportProductPerformanceLazyQuery>;
export type SummaryReportProductPerformanceQueryResult = Apollo.QueryResult<SummaryReportProductPerformanceQuery, SummaryReportProductPerformanceQueryVariables>;
export const DetailReportStaffPerformanceDocument = gql`
    query detailReportStaffPerformance($args: FilterStaffPerformance!) {
  detailReportStaffPerformance(payload: $args) {
    data {
      ...F_REPORT_STAFF_DETAIL
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_Report_Staff_DetailFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useDetailReportStaffPerformanceQuery__
 *
 * To run a query within a React component, call `useDetailReportStaffPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailReportStaffPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailReportStaffPerformanceQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDetailReportStaffPerformanceQuery(baseOptions: Apollo.QueryHookOptions<DetailReportStaffPerformanceQuery, DetailReportStaffPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailReportStaffPerformanceQuery, DetailReportStaffPerformanceQueryVariables>(DetailReportStaffPerformanceDocument, options);
      }
export function useDetailReportStaffPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailReportStaffPerformanceQuery, DetailReportStaffPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailReportStaffPerformanceQuery, DetailReportStaffPerformanceQueryVariables>(DetailReportStaffPerformanceDocument, options);
        }
export type DetailReportStaffPerformanceQueryHookResult = ReturnType<typeof useDetailReportStaffPerformanceQuery>;
export type DetailReportStaffPerformanceLazyQueryHookResult = ReturnType<typeof useDetailReportStaffPerformanceLazyQuery>;
export type DetailReportStaffPerformanceQueryResult = Apollo.QueryResult<DetailReportStaffPerformanceQuery, DetailReportStaffPerformanceQueryVariables>;
export const DetailReportProductPerformanceOrderDocument = gql`
    query detailReportProductPerformanceOrder($args: FilterProductPerformance!) {
  detailReportProductPerformanceOrder(payload: $args) {
    data {
      ...F_REPORT_PRODUCT_ORDER
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_Report_Product_OrderFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useDetailReportProductPerformanceOrderQuery__
 *
 * To run a query within a React component, call `useDetailReportProductPerformanceOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailReportProductPerformanceOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailReportProductPerformanceOrderQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useDetailReportProductPerformanceOrderQuery(baseOptions: Apollo.QueryHookOptions<DetailReportProductPerformanceOrderQuery, DetailReportProductPerformanceOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailReportProductPerformanceOrderQuery, DetailReportProductPerformanceOrderQueryVariables>(DetailReportProductPerformanceOrderDocument, options);
      }
export function useDetailReportProductPerformanceOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailReportProductPerformanceOrderQuery, DetailReportProductPerformanceOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailReportProductPerformanceOrderQuery, DetailReportProductPerformanceOrderQueryVariables>(DetailReportProductPerformanceOrderDocument, options);
        }
export type DetailReportProductPerformanceOrderQueryHookResult = ReturnType<typeof useDetailReportProductPerformanceOrderQuery>;
export type DetailReportProductPerformanceOrderLazyQueryHookResult = ReturnType<typeof useDetailReportProductPerformanceOrderLazyQuery>;
export type DetailReportProductPerformanceOrderQueryResult = Apollo.QueryResult<DetailReportProductPerformanceOrderQuery, DetailReportProductPerformanceOrderQueryVariables>;
export const FindAllRoleDocument = gql`
    query findAllRole($pagination: SearchRolePaginateInput!) {
  findAllRole(pagination: $pagination) {
    data {
      ...F_ROLE
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_RoleFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useFindAllRoleQuery__
 *
 * To run a query within a React component, call `useFindAllRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllRoleQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindAllRoleQuery(baseOptions: Apollo.QueryHookOptions<FindAllRoleQuery, FindAllRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindAllRoleQuery, FindAllRoleQueryVariables>(FindAllRoleDocument, options);
      }
export function useFindAllRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindAllRoleQuery, FindAllRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindAllRoleQuery, FindAllRoleQueryVariables>(FindAllRoleDocument, options);
        }
export type FindAllRoleQueryHookResult = ReturnType<typeof useFindAllRoleQuery>;
export type FindAllRoleLazyQueryHookResult = ReturnType<typeof useFindAllRoleLazyQuery>;
export type FindAllRoleQueryResult = Apollo.QueryResult<FindAllRoleQuery, FindAllRoleQueryVariables>;
export const FindOneRoleDocument = gql`
    query findOneRole($id: String!) {
  findOneRole(id: $id) {
    ...F_ROLE
  }
}
    ${F_RoleFragmentDoc}`;

/**
 * __useFindOneRoleQuery__
 *
 * To run a query within a React component, call `useFindOneRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneRoleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOneRoleQuery(baseOptions: Apollo.QueryHookOptions<FindOneRoleQuery, FindOneRoleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneRoleQuery, FindOneRoleQueryVariables>(FindOneRoleDocument, options);
      }
export function useFindOneRoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneRoleQuery, FindOneRoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneRoleQuery, FindOneRoleQueryVariables>(FindOneRoleDocument, options);
        }
export type FindOneRoleQueryHookResult = ReturnType<typeof useFindOneRoleQuery>;
export type FindOneRoleLazyQueryHookResult = ReturnType<typeof useFindOneRoleLazyQuery>;
export type FindOneRoleQueryResult = Apollo.QueryResult<FindOneRoleQuery, FindOneRoleQueryVariables>;
export const FindUnassignedRoleListDocument = gql`
    query findUnassignedRoleList($userId: String!, $args: SearchRolePaginateInput!) {
  findUnassignedRole(userId: $userId, paginate: $args) {
    data {
      ...F_ROLE
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_RoleFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useFindUnassignedRoleListQuery__
 *
 * To run a query within a React component, call `useFindUnassignedRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUnassignedRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUnassignedRoleListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      args: // value for 'args'
 *   },
 * });
 */
export function useFindUnassignedRoleListQuery(baseOptions: Apollo.QueryHookOptions<FindUnassignedRoleListQuery, FindUnassignedRoleListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUnassignedRoleListQuery, FindUnassignedRoleListQueryVariables>(FindUnassignedRoleListDocument, options);
      }
export function useFindUnassignedRoleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUnassignedRoleListQuery, FindUnassignedRoleListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUnassignedRoleListQuery, FindUnassignedRoleListQueryVariables>(FindUnassignedRoleListDocument, options);
        }
export type FindUnassignedRoleListQueryHookResult = ReturnType<typeof useFindUnassignedRoleListQuery>;
export type FindUnassignedRoleListLazyQueryHookResult = ReturnType<typeof useFindUnassignedRoleListLazyQuery>;
export type FindUnassignedRoleListQueryResult = Apollo.QueryResult<FindUnassignedRoleListQuery, FindUnassignedRoleListQueryVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($input: RoleAdminInputDto!) {
  createRole(input: $input) {
    ...F_ROLE
  }
}
    ${F_RoleFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, options);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($id: String!, $input: RoleAdminInputDto!) {
  updateRole(id: $id, input: $input) {
    ...F_ROLE
  }
}
    ${F_RoleFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, options);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const RemoveRoleDocument = gql`
    mutation removeRole($ids: [String!]!) {
  removeRole(ids: $ids) {
    status
  }
}
    `;
export type RemoveRoleMutationFn = Apollo.MutationFunction<RemoveRoleMutation, RemoveRoleMutationVariables>;

/**
 * __useRemoveRoleMutation__
 *
 * To run a mutation, you first call `useRemoveRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRoleMutation, { data, loading, error }] = useRemoveRoleMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRemoveRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRoleMutation, RemoveRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRoleMutation, RemoveRoleMutationVariables>(RemoveRoleDocument, options);
      }
export type RemoveRoleMutationHookResult = ReturnType<typeof useRemoveRoleMutation>;
export type RemoveRoleMutationResult = Apollo.MutationResult<RemoveRoleMutation>;
export type RemoveRoleMutationOptions = Apollo.BaseMutationOptions<RemoveRoleMutation, RemoveRoleMutationVariables>;
export const StaffListDocument = gql`
    query staffList($args: AdminStaffPaginate!) {
  findStaffByAdmin(paginate: $args) {
    data {
      ...F_STAFF
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_StaffFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useStaffListQuery__
 *
 * To run a query within a React component, call `useStaffListQuery` and pass it any options that fit your needs.
 * When your component renders, `useStaffListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStaffListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useStaffListQuery(baseOptions: Apollo.QueryHookOptions<StaffListQuery, StaffListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StaffListQuery, StaffListQueryVariables>(StaffListDocument, options);
      }
export function useStaffListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StaffListQuery, StaffListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StaffListQuery, StaffListQueryVariables>(StaffListDocument, options);
        }
export type StaffListQueryHookResult = ReturnType<typeof useStaffListQuery>;
export type StaffListLazyQueryHookResult = ReturnType<typeof useStaffListLazyQuery>;
export type StaffListQueryResult = Apollo.QueryResult<StaffListQuery, StaffListQueryVariables>;
export const CreateUserByAdminDocument = gql`
    mutation createUserByAdmin($input: AdminInputDto!) {
  createUserByAdmin(input: $input) {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;
export type CreateUserByAdminMutationFn = Apollo.MutationFunction<CreateUserByAdminMutation, CreateUserByAdminMutationVariables>;

/**
 * __useCreateUserByAdminMutation__
 *
 * To run a mutation, you first call `useCreateUserByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserByAdminMutation, { data, loading, error }] = useCreateUserByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserByAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserByAdminMutation, CreateUserByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserByAdminMutation, CreateUserByAdminMutationVariables>(CreateUserByAdminDocument, options);
      }
export type CreateUserByAdminMutationHookResult = ReturnType<typeof useCreateUserByAdminMutation>;
export type CreateUserByAdminMutationResult = Apollo.MutationResult<CreateUserByAdminMutation>;
export type CreateUserByAdminMutationOptions = Apollo.BaseMutationOptions<CreateUserByAdminMutation, CreateUserByAdminMutationVariables>;
export const UpdateUserByAdminDocument = gql`
    mutation updateUserByAdmin($input: AdminInputDto!) {
  updateUserByAdmin(input: $input) {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;
export type UpdateUserByAdminMutationFn = Apollo.MutationFunction<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>;

/**
 * __useUpdateUserByAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByAdminMutation, { data, loading, error }] = useUpdateUserByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserByAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>(UpdateUserByAdminDocument, options);
      }
export type UpdateUserByAdminMutationHookResult = ReturnType<typeof useUpdateUserByAdminMutation>;
export type UpdateUserByAdminMutationResult = Apollo.MutationResult<UpdateUserByAdminMutation>;
export type UpdateUserByAdminMutationOptions = Apollo.BaseMutationOptions<UpdateUserByAdminMutation, UpdateUserByAdminMutationVariables>;
export const FindUsersByAdminDocument = gql`
    query findUsersByAdmin($pagination: AdminPaginate!) {
  findUsersByAdmin(pagination: $pagination) {
    data {
      ...F_USER
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_UserFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useFindUsersByAdminQuery__
 *
 * To run a query within a React component, call `useFindUsersByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersByAdminQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFindUsersByAdminQuery(baseOptions: Apollo.QueryHookOptions<FindUsersByAdminQuery, FindUsersByAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUsersByAdminQuery, FindUsersByAdminQueryVariables>(FindUsersByAdminDocument, options);
      }
export function useFindUsersByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUsersByAdminQuery, FindUsersByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUsersByAdminQuery, FindUsersByAdminQueryVariables>(FindUsersByAdminDocument, options);
        }
export type FindUsersByAdminQueryHookResult = ReturnType<typeof useFindUsersByAdminQuery>;
export type FindUsersByAdminLazyQueryHookResult = ReturnType<typeof useFindUsersByAdminLazyQuery>;
export type FindUsersByAdminQueryResult = Apollo.QueryResult<FindUsersByAdminQuery, FindUsersByAdminQueryVariables>;
export const FindUserByAdminDocument = gql`
    query findUserByAdmin($id: String!) {
  findUserByAdmin(id: $id) {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;

/**
 * __useFindUserByAdminQuery__
 *
 * To run a query within a React component, call `useFindUserByAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUserByAdminQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindUserByAdminQuery(baseOptions: Apollo.QueryHookOptions<FindUserByAdminQuery, FindUserByAdminQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUserByAdminQuery, FindUserByAdminQueryVariables>(FindUserByAdminDocument, options);
      }
export function useFindUserByAdminLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUserByAdminQuery, FindUserByAdminQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUserByAdminQuery, FindUserByAdminQueryVariables>(FindUserByAdminDocument, options);
        }
export type FindUserByAdminQueryHookResult = ReturnType<typeof useFindUserByAdminQuery>;
export type FindUserByAdminLazyQueryHookResult = ReturnType<typeof useFindUserByAdminLazyQuery>;
export type FindUserByAdminQueryResult = Apollo.QueryResult<FindUserByAdminQuery, FindUserByAdminQueryVariables>;
export const DeleteUsersByAdminDocument = gql`
    mutation deleteUsersByAdmin($ids: [String!]!) {
  deleteUsersByAdmin(ids: $ids) {
    id
  }
}
    `;
export type DeleteUsersByAdminMutationFn = Apollo.MutationFunction<DeleteUsersByAdminMutation, DeleteUsersByAdminMutationVariables>;

/**
 * __useDeleteUsersByAdminMutation__
 *
 * To run a mutation, you first call `useDeleteUsersByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUsersByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUsersByAdminMutation, { data, loading, error }] = useDeleteUsersByAdminMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteUsersByAdminMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUsersByAdminMutation, DeleteUsersByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUsersByAdminMutation, DeleteUsersByAdminMutationVariables>(DeleteUsersByAdminDocument, options);
      }
export type DeleteUsersByAdminMutationHookResult = ReturnType<typeof useDeleteUsersByAdminMutation>;
export type DeleteUsersByAdminMutationResult = Apollo.MutationResult<DeleteUsersByAdminMutation>;
export type DeleteUsersByAdminMutationOptions = Apollo.BaseMutationOptions<DeleteUsersByAdminMutation, DeleteUsersByAdminMutationVariables>;
export const RemoveUserChannelDocument = gql`
    mutation removeUserChannel($input: AdminInputDto!) {
  removeUserChannel(input: $input) {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;
export type RemoveUserChannelMutationFn = Apollo.MutationFunction<RemoveUserChannelMutation, RemoveUserChannelMutationVariables>;

/**
 * __useRemoveUserChannelMutation__
 *
 * To run a mutation, you first call `useRemoveUserChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserChannelMutation, { data, loading, error }] = useRemoveUserChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserChannelMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserChannelMutation, RemoveUserChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserChannelMutation, RemoveUserChannelMutationVariables>(RemoveUserChannelDocument, options);
      }
export type RemoveUserChannelMutationHookResult = ReturnType<typeof useRemoveUserChannelMutation>;
export type RemoveUserChannelMutationResult = Apollo.MutationResult<RemoveUserChannelMutation>;
export type RemoveUserChannelMutationOptions = Apollo.BaseMutationOptions<RemoveUserChannelMutation, RemoveUserChannelMutationVariables>;
export const RemoveUserRoleDocument = gql`
    mutation removeUserRole($input: AdminInputDto!) {
  removeUserRole(input: $input) {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;
export type RemoveUserRoleMutationFn = Apollo.MutationFunction<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>;

/**
 * __useRemoveUserRoleMutation__
 *
 * To run a mutation, you first call `useRemoveUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserRoleMutation, { data, loading, error }] = useRemoveUserRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>(RemoveUserRoleDocument, options);
      }
export type RemoveUserRoleMutationHookResult = ReturnType<typeof useRemoveUserRoleMutation>;
export type RemoveUserRoleMutationResult = Apollo.MutationResult<RemoveUserRoleMutation>;
export type RemoveUserRoleMutationOptions = Apollo.BaseMutationOptions<RemoveUserRoleMutation, RemoveUserRoleMutationVariables>;
export const DuplicateUserByAdminDocument = gql`
    mutation duplicateUserByAdmin($input: String!) {
  duplicateUserByAdmin(id: $input) {
    ...F_USER
  }
}
    ${F_UserFragmentDoc}`;
export type DuplicateUserByAdminMutationFn = Apollo.MutationFunction<DuplicateUserByAdminMutation, DuplicateUserByAdminMutationVariables>;

/**
 * __useDuplicateUserByAdminMutation__
 *
 * To run a mutation, you first call `useDuplicateUserByAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateUserByAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateUserByAdminMutation, { data, loading, error }] = useDuplicateUserByAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateUserByAdminMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateUserByAdminMutation, DuplicateUserByAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateUserByAdminMutation, DuplicateUserByAdminMutationVariables>(DuplicateUserByAdminDocument, options);
      }
export type DuplicateUserByAdminMutationHookResult = ReturnType<typeof useDuplicateUserByAdminMutation>;
export type DuplicateUserByAdminMutationResult = Apollo.MutationResult<DuplicateUserByAdminMutation>;
export type DuplicateUserByAdminMutationOptions = Apollo.BaseMutationOptions<DuplicateUserByAdminMutation, DuplicateUserByAdminMutationVariables>;
export const WarehouseListDocument = gql`
    query warehouseList($args: WarehousePaginate!) {
  findWarehousesByAdmin(paginate: $args) {
    data {
      ...F_WAREHOUSE
    }
    pagination {
      ...F_PAGINATION
    }
  }
}
    ${F_WarehouseFragmentDoc}
${F_PaginationFragmentDoc}`;

/**
 * __useWarehouseListQuery__
 *
 * To run a query within a React component, call `useWarehouseListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarehouseListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarehouseListQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useWarehouseListQuery(baseOptions: Apollo.QueryHookOptions<WarehouseListQuery, WarehouseListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WarehouseListQuery, WarehouseListQueryVariables>(WarehouseListDocument, options);
      }
export function useWarehouseListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarehouseListQuery, WarehouseListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WarehouseListQuery, WarehouseListQueryVariables>(WarehouseListDocument, options);
        }
export type WarehouseListQueryHookResult = ReturnType<typeof useWarehouseListQuery>;
export type WarehouseListLazyQueryHookResult = ReturnType<typeof useWarehouseListLazyQuery>;
export type WarehouseListQueryResult = Apollo.QueryResult<WarehouseListQuery, WarehouseListQueryVariables>;