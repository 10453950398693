import { Switch } from "antd";
import {
  Avatar,
  AwesomeTableComponent,
  Button,
  IColumnsProps,
  Notifications,
  Progress,
  StringUtils,
} from "d-react-components";
import {
  chain,
  isEmpty,
  isNil,
  isObject,
  isString,
  omitBy,
  orderBy,
  sumBy,
} from "lodash";
import React, { useContext, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SORT_DIRECTION } from "../../constants/common";
import { REPORT_TYPE } from "../../constants/report";
import { Channel } from "../../interfaces/common";
import {
  useDetailReportStaffPerformanceLazyQuery,
  useExportStaffPerformanceLazyQuery,
} from "../../network/hooks";
import { exportToXLS } from "../../utils/file";
import { moneyFormatter } from "../../utils/money";
import { loadAllPaginatedData } from "../../utils/promises";
import { convertToLocalTime } from "../../utils/time";
import ReportCtx from "../common/AppContext/ReportContext";
import ReportHeader, {
  FilterDataProps,
} from "../common/report/header/ReportHeader";
import TableSummary from "../common/Table/TableSummary";
import ReportContext from "./ReportContext";

const StaffPerformanceContent = () => {
  const [sortBy, setSortBy] = useState<any>();
  const { filterData, setFilterData, channelList } = useContext(ReportCtx);
  const [comprehensiveMode, setComprehensiveMode] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();
  const [exportReport, { data }] = useExportStaffPerformanceLazyQuery();

  const getPayload = () => {
    return {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      staffs: filterData.staffs?.length
        ? filterData.staffs?.map((item: any) => item.refId)
        : null,
      store: filterData.warehouses?.map((item: any) => item.refId),
      includeStaffsWithoutSales: filterData.includeStaffsWithoutSales,
    };
  };

  const onChangeFilter = (filterData: FilterDataProps) => {
    if (!filterData?.orderSource?.length) {
      return Promise.resolve([]);
    }
    const payload = getPayload();
    Progress.show(
      {
        method: exportReport,
        params: [
          {
            variables: {
              args: {
                ...payload,
                ...(sortBy?.order
                  ? {
                      sort: {
                        sortBy: sortBy.field,
                        sortDirection:
                          sortBy.order === SORT_DIRECTION.ASC ? 1 : -1,
                      },
                    }
                  : {}),
              },
            },
          },
        ],
      },
      (resp: any) => {}
    );
  };

  const handleExportData = () => {
    // const payload = getPayload();
    // Progress.show(
    //   {
    //     method: exportReport,
    //     params: [
    //       {
    //         variables: {
    //           args: {
    //             ...payload,
    //             ...(sortBy?.order
    //               ? {
    //                   sort: {
    //                     sortBy: sortBy.field,
    //                     sortDirection:
    //                       sortBy.order === SORT_DIRECTION.ASC ? 1 : -1,
    //                   },
    //                 }
    //               : {}),
    //           },
    //         },
    //       },
    //     ],
    //   },
    //   (resp: any) => {
    //     const reportItems =
    //       resp?.data?.exportStaffPerformance?.map((item: any) => ({
    //         ...item,
    //       })) ?? [];
    //     const mappedData = reportItems.map(
    //       (item: StaffPerformanceItem, index: number) => ({
    //         No: index + 1,
    //         Name: item?.admin?.fullname,
    //         "Employee Id": item?.admin?.username,
    //         "Sale Quantity": item.count,
    //         "Total Sales Amount": item?.total,
    //         "Average Order Value":
    //           Math.round(item.total) / parseInt(item?.count),
    //       })
    //     );
    //     exportToXLS(mappedData, "export");
    //   }
    // );

    
    const getReachedKPI = (item: any) => {
      const reachedKPI = item.kpi ? (item.subTotal / item.kpi) * 100 : 0;
      const overKPI = reachedKPI - 100;
      return `${reachedKPI <= 100 ? reachedKPI.toFixed(2) : 100}% ${overKPI > 0 ? `(+${overKPI.toFixed(2)}%)` : ``}`;
    }


    const sortBy = tableRef.current?.state?.sorter;
    const mappedData = data?.exportStaffPerformance
      ? orderBy(
          data.exportStaffPerformance,
          sortBy?.field ?? "admin.fullname",
          sortBy?.order === SORT_DIRECTION.DESC ? "desc" : "asc"
        ).map((item: any, index: number) => ({
          No: `${index + 1}`,
          Name: item?.admin?.fullname,
          "Employee Id": item?.admin?.username,
          "Sale Quantity": item.count,
          "Total Sales Amount": item?.subTotal,
          "Total Sales Amount (Net)": item?.total,
          "Target KPI": item?.kpi,
          "Remaining KPI": item.kpi > item.subTotal ? item?.kpi - item?.subTotal : 0,
          "Reached KPI (%)": getReachedKPI(item),
          "Average Order Value":
            Math.round(item.subTotal) / parseInt(item?.count),
          "Average Order Value (Net)":
            Math.round(item.total) / parseInt(item?.count),
        }))
      : [];
    mappedData.push({
      No: "",
      Name: "",
      "Employee Id": "",
      "Sale Quantity": sumBy(mappedData, "Sale Quantity"),
      "Total Sales Amount": sumBy(mappedData, "Total Sales Amount"),
      "Total Sales Amount (Net)": sumBy(mappedData, "Total Sales Amount (Net)"),
      "Target KPI": sumBy(mappedData, "Target KPI"),
      "Remaining KPI":
        sumBy(mappedData, "Target KPI") -
        sumBy(mappedData, "Total Sales Amount"),
      "Reached KPI (%)": getReachedKPI(sumData),
      "Average Order Value":
        sumBy(mappedData, "Total Sales Amount") /
        sumBy(data?.exportStaffPerformance, "count"),
      "Average Order Value (Net)":
        sumBy(mappedData, "Total Sales Amount (Net)") /
        sumBy(data?.exportStaffPerformance, "count"),
    });
    exportToXLS(mappedData, "export");
  };

  // const loadTableData = (paging: any, sorter: any) => {
  //   if (!filterData?.orderSource?.length) {
  //     return Promise.resolve([]);
  //   }
  //   setSortBy(sorter);
  //   const payload = getPayload();
  //   return getReport({
  //     variables: {
  //       args: {
  //         ...omitBy(payload, isNil),
  //         page: paging.pageIndex,
  //         limit: paging.pageSize,
  //         ...(sorter?.order
  //           ? {
  //               sort: {
  //                 sortBy: sorter.field,
  //                 sortDirection: sorter.order === SORT_DIRECTION.ASC ? 1 : -1,
  //               },
  //             }
  //           : {
  //               sort: {
  //                 sortBy: "admin.fullname",
  //                 sortDirection: 1,
  //               },
  //             }),
  //       },
  //     },
  //   });
  // };

  const tableColumns: IColumnsProps = useMemo(
    () => [
      {
        title: t("name"),
        dataIndex: "admin",
        align: "left",
        width: 200,
        render: (admin) => {
          return isString(admin) ? (
            admin
          ) : (
            <div className="flex items-center">
              <Avatar src={admin?.avatar} size="x-small" className="mr-2" />{" "}
              {admin?.fullname}
            </div>
          );
        },
      },
      {
        title: t("employeeId"),
        dataIndex: "admin",
        align: "left",
        width: 100,
        render: (admin) => {
          return admin?.username;
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "count",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.count - b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "subTotal",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.subTotal - b?.subTotal;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        defaultSortOrder: SORT_DIRECTION.DESC,
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "total",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.total - b?.total;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("targetKPI"),
        dataIndex: "kpi",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.kpi - b?.kpi;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("remainingKPI"),
        dataIndex: "remainingKPI",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return (a.kpi - a.subTotal) - (b.kpi - b.subTotal);
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return item.kpi > item.subTotal
            ? moneyFormatter.format(item.kpi - item.subTotal)
            : 0;
        },
      },
      {
        title: t("reachedKPI"),
        dataIndex: "reachedKPI",
        align: "left",
        width: 140,
        sorter: (a, b) => {
          return (
            (a.kpi ? a.subTotal / a.kpi : 0) - (b.kpi ? b.subTotal / b.kpi : 0)
          );
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          const reachedKPI = item.kpi ? (item.subTotal / item.kpi) * 100 : 0;
          const overKPI = reachedKPI - 100;
          return (
            <>
              {reachedKPI <= 100 ? reachedKPI.toFixed(2) : 100}%{" "}
              {overKPI > 0 && <span className="text-green-500">(+{overKPI.toFixed(2)}%)</span>}
            </>
          );
        },
      },
      {
        title: t("avgOrderValue"),
        dataIndex: "subTotal",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.subTotal / a?.count - b?.subTotal / b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return item.count
            ? moneyFormatter.format(item.subTotal / item.count)
            : 0;
        },
      },
      {
        title: t("avgOrderValueNet"),
        dataIndex: "total",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.total / a?.count - b?.total / b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return item.count
            ? moneyFormatter.format(item.total / item.count)
            : 0;
        },
      },
    ],
    [t]
  );

  const sumData = useMemo(() => {
    const tableData = data?.exportStaffPerformance;
    const kpiSumPercent = sumBy(data?.exportStaffPerformance, (item) =>
      item.subTotal && item.kpi ? (item.subTotal / item.kpi) * 100 : 100
    );
    return isEmpty(tableData)
      ? {}
      : {
          admin: t("sum"),
          total: sumBy(tableData, "total"),
          subTotal: sumBy(tableData, "subTotal"),
          count: sumBy(tableData, "count"),
          kpi: sumBy(tableData, "kpi"),
          reachedKPI: (kpiSumPercent / (tableData?.length ?? 1)).toFixed(2),
        };
  }, [data?.exportStaffPerformance]);

  const tableData = useMemo(() => {
    return data?.exportStaffPerformance?.map((i) => ({
      ...i,
      id: StringUtils.generateCode(8),
    }));
  }, [data?.exportStaffPerformance]);

  const summaryCols = useMemo(() => {
    // const columns = tableColumns?.map((col: any) =>
    //   col.dataIndex === "reachedKPI"
    //     ? { ...col, render: (value: any) => `${value}%` }
    //     : col
    // );
    return comprehensiveMode
      ? [{ title: "", index: "id", render: () => "" }, ...tableColumns]
      : tableColumns;
  }, [comprehensiveMode, tableColumns]);

  const renderExpandableProductTable = (staff: any) => {
    return (
      <ExpandedRow
        filterData={filterData}
        channelList={channelList}
        staff={staff}
        summaryData={sumData}
      />
    );
  };

  const expandableTable = {
    expandedRowRender: renderExpandableProductTable,
    rowExpandable: () => true,
    defaultExpandAllRows: false,
    expandedRowKeys,
    onExpandedRowsChange: (items: any) => setExpandedRowKeys(items),
  };

  return (
    <div>
      <ReportHeader
        onChangeFilter={onChangeFilter}
        handleExportData={handleExportData}
        reportType={REPORT_TYPE.STAFF_PERFORMANCE}
      />
      <AwesomeTableComponent
        columns={tableColumns}
        ref={tableRef}
        title={() => (
          <div className="flex">
            <span className="flex-1">{t("report:dataTable")}</span>
            <span className="mr-2">
              <Switch
                checked={filterData?.includeStaffsWithoutSales}
                onChange={() =>
                  setFilterData({
                    ...filterData,
                    includeStaffsWithoutSales:
                      !filterData?.includeStaffsWithoutSales,
                  })
                }
              />{" "}
              {t("includeStaffWithoutSale")}
            </span>
            <span>
              <Switch
                checked={comprehensiveMode}
                onChange={() => setComprehensiveMode(!comprehensiveMode)}
              />{" "}
              {t("comprehensiveMode")}
            </span>
          </div>
        )}
        dataSource={tableData}
        rowKey={(item) => item?.id}
        summary={() => <TableSummary data={sumData} columns={summaryCols} />}
        expandable={comprehensiveMode ? expandableTable : undefined}
        // transformer={(res) => {
        //   const reportItems =
        //     res?.data?.reportStaffPerformance?.data?.map((item: any) => ({
        //       ...item,
        //       created: convertToLocalTime(item.created),
        //     })) ?? [];
        //   return reportItems;
        // }}
        // getTotalItems={(response) => {
        //   return response?.data?.reportStaffPerformance?.pagination?.items ?? 0;
        // }}
      />
    </div>
  );
};

const ExpandedRow = ({ filterData, channelList, staff, summaryData }: any) => {
  const [getReportDetail] = useDetailReportStaffPerformanceLazyQuery();
  const tableRef = useRef<any>(null);
  const { t } = useTranslation();

  const getPayload = () => {
    const payload = {
      orderSource: filterData?.orderSource,
      channels: filterData?.channels?.length
        ? filterData.channels
        : channelList.map((channel: Channel) => channel.refId),
      start: filterData?.currentRange?.[0].startOf("d").toDate(),
      end: filterData?.currentRange?.[1].endOf("d").toDate(),
      store: filterData.warehouses?.map((item: any) => item.refId),
      staffs: [staff?.adminId?.toString()],
    };
    return omitBy(payload, isNil);
  };

  const loadTableData = (paging: any, sorter: any) => {
    return getReportDetail({
      variables: {
        args: {
          ...getPayload(),
          page: paging.pageIndex,
          limit: paging.pageSize,
          ...(sorter?.order
            ? {
                sort: {
                  sortBy: sorter.field,
                  sortDirection: sorter.order === SORT_DIRECTION.ASC ? 1 : -1,
                },
              }
            : {
                sort: {
                  sortBy: "quantity",
                  sortDirection: -1,
                },
              }),
        },
      },
    });
  };

  const tableColumns: IColumnsProps = useMemo(() => {
    return [
      {
        title: t("warehouse"),
        dataIndex: "store",
        align: "left",
        width: 150,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("channel"),
        dataIndex: "channel",
        align: "left",
        width: 150,
        render: (data: any) => {
          return data?.name;
        },
      },
      {
        title: t("saleQuantity"),
        dataIndex: "count",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.count - b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("totalSalesAmount"),
        dataIndex: "subTotal",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.subTotal - b?.subTotal;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("totalSalesAmountNet"),
        dataIndex: "total",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.total - b?.total;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(value);
        },
      },
      {
        title: t("avgOrderValue"),
        dataIndex: "subTotal",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.subTotal / a?.count - b?.subTotal / b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(item.subTotal / item.count);
        },
      },
      {
        title: t("avgOrderValueNet"),
        dataIndex: "total",
        align: "left",
        width: 100,
        sorter: (a, b) => {
          return a?.total / a?.count - b?.total / b?.count;
        },
        sortDirections: [
          SORT_DIRECTION.ASC,
          SORT_DIRECTION.DESC,
          SORT_DIRECTION.ASC,
        ],
        render: (value, item) => {
          return moneyFormatter.format(item.total / item.count);
        },
      },
    ];
  }, [t]);

  const exportReport = async () => {
    Progress.show(
      {
        method: loadAllPaginatedData,
        params: [getReportDetail, "detailReportStaffPerformance", getPayload()],
      },
      (resp) => {
        const exportedData: any[] = resp as any[];

        const mappedData = [
          {
            Name: staff?.admin?.fullname,
            "Employee Id": staff?.admin?.username,
            Warehouse: "",
            Channel: "",
            "Sale Quantity": staff.count,
            "Total Sales Amount": staff?.subTotal?.toFixed(0),
            "Total Sales Amount (Net)": staff?.total?.toFixed(0),
            "Average Order Value": (
              Math.round(staff.subTotal) / parseInt(staff?.count)
            ).toFixed(0),
            "Average Order Value (Net)": (
              Math.round(staff.total) / parseInt(staff?.count)
            ).toFixed(0),
          },
        ];

        const rowData = chain(exportedData)
          .orderBy("store.name")
          .map((item: any) => ({
            Name: staff?.admin?.fullname,
            "Employee Id": staff?.admin?.username,
            Warehouse: item?.store?.name,
            Channel: item?.channel?.name,
            "Sale Quantity": item.count,
            "Total Sales Amount": item?.subTotal?.toFixed(0),
            "Total Sales Amount (Net)": item?.total?.toFixed(0),
            "Average Order Value": (
              Math.round(item.subTotal) / parseInt(item?.count)
            ).toFixed(0),
            "Average Order Value (Net)": (
              Math.round(item.total) / parseInt(item?.count)
            ).toFixed(0),
          }))
          .value();
        const exportData = [...mappedData, ...rowData];
        exportToXLS(exportData, "export");
      },
      (err: any) => {
        Notifications.showError(err);
      }
    );
  };

  return (
    <div className="relative">
      <AwesomeTableComponent
        columns={tableColumns}
        ref={tableRef}
        source={loadTableData}
        rowKey={(item) => item?.id}
        transformer={(res) => {
          const reportItems =
            res?.data?.detailReportStaffPerformance?.data?.map((item: any) => ({
              ...item,
              created: convertToLocalTime(item.created),
            })) ?? [];
          return reportItems;
        }}
        getTotalItems={(response) => {
          return (
            response?.data?.detailReportProductPerformance?.pagination?.items ??
            0
          );
        }}
      />
      <Button
        className="absolute bottom-2 left-0"
        iconName="cloud_download"
        onClick={exportReport}
      >
        {t("export")}
      </Button>
    </div>
  );
};

const StaffPerformance = () => {
  return (
    <ReportContext>
      <StaffPerformanceContent />
    </ReportContext>
  );
};

export default StaffPerformance;
